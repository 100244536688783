import { all, takeLatest } from 'redux-saga/effects';
import * as onboardingActions from '../actions';
import getOnboardingState from './worker.getOnboardingState';
import putOnboardingState from './worker.putOnboardingState';

function* onboardingSaga() {
  yield all([
    takeLatest(onboardingActions.requestGetOnboardingState.type, getOnboardingState),
    takeLatest(onboardingActions.requestPutOnboardingState.type, putOnboardingState),
  ]);
}

export default onboardingSaga;
