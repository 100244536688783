import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@karma/components';
import { CartIcon } from '@karma/components/icons';
import GetTheButton from 'components/molecules/GetTheButton/GetTheButton';

const ModalBodySuggestInstallExtension = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={20} direction="column" align="center">
      <CartIcon variant="symbol" width={60} height={60} />
      <div>
        <Typography variant="h3" align="center" bMargin={8}>
          {t('app:KeepYourWants')}
        </Typography>
        <Typography variant="paragraphLarge" align="center">
          {t('app:KeepYourWants2')}
        </Typography>
      </div>
      <GetTheButton content="app:startSaving" />
    </Stack>
  );
};

export default ModalBodySuggestInstallExtension;
