import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodyCongratsUser from 'components/molecules/ModalBodyCongratsUser/ModalBodyCongratsUser';
import Modal from 'components/templates/Modal/Modal';

const ModalCongratsUser = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.CONGRATS_USER} onClose={closeModal}>
      <ModalBodyCongratsUser {...props} />
    </Modal>
  );
};

ModalCongratsUser.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalCongratsUser;
