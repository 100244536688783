import { call, put } from 'redux-saga/effects';
import { handleServerError } from 'store/alerts/actions';
import * as onboardingActions from '../actions';
import * as onboardingApi from '../api';

function* putOnboardingState(action) {
  try {
    yield call(onboardingApi.putOnboardingState, action.payload);
    yield put(onboardingActions.successPutOnboardingState(action.payload));
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default putOnboardingState;
