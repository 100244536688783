import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  onboardingState: {},
  loaded: false,
  lastSavedTag: null,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    successGetOnboardingState: (state, action) => {
      return {
        ...state,
        onboardingState: action.payload,
        loaded: true,
      };
    },
    successPutOnboardingState: (state, action) => {
      return {
        ...state,
        onboardingState: {
          ...state.onboardingState,
          ...action.payload,
        },
      };
    },
    setLastSavedTag: (state, action) => {
      return {
        ...state,
        lastSavedTag: action.payload,
      };
    },
  },
});

export default onboardingSlice.reducer;
