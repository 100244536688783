import { call, put, select } from 'redux-saga/effects';
import getBrowser from 'functions/getBrowser';
import isTablet from 'functions/isTablet';
import { handleServerError } from 'store/alerts/actions';
import * as userSelectors from 'store/user/selectors';
import * as myItemsApi from 'store/myItems/store/api';
import { ITEMS } from 'consts/localStorage';
import {
  pushBackCongratsGuestModal,
  pushBackCongratsUserModal,
  pushBackSuggestInstallExtensionModal,
  pushBackSuggestInstallRemovedExtensionModal,
  pushBackCongratulateCompleteSignUpModal,
} from 'store/modals/actions';
import { getExtInstalled } from 'store/extension/selectors';
import { getQueryParam } from 'functions/urlHelpers';
import { QUERY_PARAMS } from 'consts/queryParams';
import { MODAL_TYPES } from 'consts/modals';
import * as onboardingApi from '../api';
import * as onboardingActions from '../actions';

const CONGRATS_USER_MODAL_SHOWN = 'greeting_modal_shown';

const checkIsExtensionInstalledInCurrentBrowser = data => {
  return data.extension_installed.includes(getBrowser());
};

const checkIsExtensionOnboardingFinished = data => {
  return !!data.extension_tutorial_finished.length;
};

const checkIsExtensionRemovedInCurrentBrowser = data => {
  return data.extension_uninstalled.includes(getBrowser());
};

function* getOnboardingState() {
  try {
    const { data } = yield call(onboardingApi.getOnboardingState);
    const guest = yield select(userSelectors.getGuest);
    const extInstalled = yield select(getExtInstalled);

    const completeSignUpModalDelay = localStorage.getItem(ITEMS.completeSignUpModalDelay);
    const congratulateSignupModalShown = localStorage.getItem(ITEMS.congratulateSignupModal);

    yield put(onboardingActions.successGetOnboardingState(data));

    const { [CONGRATS_USER_MODAL_SHOWN]: congrats_user_modal_shown = false, tags_count } = data;

    const modalsQueryParam = getQueryParam(QUERY_PARAMS.modals);
    const onboardingModal = getQueryParam(QUERY_PARAMS.onboardingModal);

    const isFromCompleteSignUp =
      typeof modalsQueryParam === 'string' && modalsQueryParam.includes(MODAL_TYPES.completeSignUp);

    if (onboardingModal) {
      return;
    }

    if (guest && !isFromCompleteSignUp && (!completeSignUpModalDelay || Date.now() > completeSignUpModalDelay)) {
      if (tags_count > 0 && checkIsExtensionInstalledInCurrentBrowser(data)) {
        yield put(pushBackCongratulateCompleteSignUpModal());

        return;
      }

      if (tags_count > 0 && !congratulateSignupModalShown) {
        yield put(pushBackCongratsGuestModal());

        return;
      }
    } else if (!guest) {
      if (
        !checkIsExtensionInstalledInCurrentBrowser(data) &&
        !checkIsExtensionOnboardingFinished(data) &&
        !isTablet() &&
        !extInstalled
      ) {
        yield put(pushBackSuggestInstallExtensionModal());

        return;
      }

      if (checkIsExtensionOnboardingFinished(data) && !congrats_user_modal_shown && tags_count > 0) {
        const queryData = { page: 1, per_page: 1, sort_by: 'created_at' };
        const response = yield call(myItemsApi.fetchMyItemsRequest, queryData);

        if (response.data.data.length) {
          yield put(onboardingActions.setLastSavedTag(response.data.data[0]));
          yield put(pushBackCongratsUserModal());
          yield put(onboardingActions.requestPutOnboardingState({ [CONGRATS_USER_MODAL_SHOWN]: true }));
        }

        return;
      }
    }

    if (checkIsExtensionRemovedInCurrentBrowser(data) && tags_count === 0) {
      yield put(pushBackSuggestInstallRemovedExtensionModal());
    }
  } catch (error) {
    yield put(handleServerError(error));
  }
}

export default getOnboardingState;
