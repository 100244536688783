import { createSelector } from '@reduxjs/toolkit';

const getRootEntity = ({ onboarding }) => {
  return onboarding;
};

export const getOnboardingState = createSelector([getRootEntity], ({ onboardingState }) => {
  return onboardingState;
});

export const getLoaded = createSelector([getRootEntity], ({ loaded }) => {
  return loaded;
});

export const getLastSavedTag = createSelector([getRootEntity], ({ lastSavedTag }) => {
  return lastSavedTag;
});
