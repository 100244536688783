import { useTranslation } from 'react-i18next';
import { KarmaLogo, Stack, Typography } from '@karma/components';
import GetTheButton from 'components/molecules/GetTheButton/GetTheButton';

const ModalBodySuggestInstallRemovedExtension = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={20} direction="column" align="center">
      <KarmaLogo variant="symbol" width={60} />

      <div>
        <Typography variant="h3" align="center" bMargin={8}>
          {t('app:getExtension')}
        </Typography>

        <Typography variant="paragraphLarge" align="center">
          {t('app:getExtension2')}
        </Typography>
      </div>

      <GetTheButton content="app:getExtensionButton" />
    </Stack>
  );
};

export default ModalBodySuggestInstallRemovedExtension;
