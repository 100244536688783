export const PRODUCT_MODAL = 'product-modal';
export const TRENDING_NOW_LIST = 'trending-now-list';
export const POPULAR_RETAILER_LIST = 'popular-retailer-list';
export const UNINSTALL_INTERVIEW = 'uninstall-interview';
export const X_PLATFORM_ON_SEARCH_PAGE = 'x-platform-on-search-page';
export const X_PLATFORM_ON_HOME_PAGE = 'x-platform-on-home-page';
export const NEW_GENERATION = 'new-generation';

// PIT - POST INSTALL TESTS
export const PIT_GROUP = 'pit-group';
export const PIT_AMAZON = 'pit-amazon';
export const PIT_WALMART = 'pit-walmart';
export const PIT_CHEWY = 'pit-chewy';
export const PIT_NEWSLETTER = 'pit-newsletter';
export const PIT_TRAVEL = 'pit-travel';
export const PIT_TRAVEL_GROUP = 'pit-travel-group';
