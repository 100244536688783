/* eslint-disable no-template-curly-in-string */
export default {
  featured: 'Destaques',
  popular: 'Popular',
  couponsAndDeals: 'Cupons e Ofertas',
  yourFavorites: 'Seus Favoritos',
  coupons: 'Cupons',
  headerCoupons: 'Karma | Cupons',
  lastSaved: 'Últimos Salvos',
  favorites: 'Favoritos',
  trending: 'Tendência',
  weAreLoving: 'Nós adoramos',
  karmaHome: 'Karma | Casa',
  goodMorning: 'Bom dia',
  goodAfternoon: 'Boa tarde',
  goodEvening: 'Boa noite',
  addFavoriteStores: 'Adicionar Lojas Favoritas',
  nothingFound: 'Não encontrado',
  number: '{{value, number}}',
  clearNumber: '{{value, clearNumber}}',
  saveSettings: 'Salvar configurações',
  enableBrand: 'Habilitar {{name}}',
  chrome: 'Chrome',
  edge: 'Edge',
  firefox: 'Firefox',
  ie: 'Internet Explorer',
  oldEdge: 'Edge',
  opera: 'Opera',
  safari: 'Safari',
  remove: 'Remover',
  sponsored: 'Patrocinado',
  seeAll: 'Ver tudo',
  favorite: 'Favorito',
  onSale: 'Em Promoção',
  lowInStock: 'Estoque Baixo',
  backInStock: 'Voltou ao Estoque',
  coupon: 'Cupom',
  soldOut: 'Esgotado',
  fulfilled: 'Preenchido',
  deal: 'Oferta',
  price: '{{currency}}{{value, number(minimumFractionDigits: 2)}}',
  dayRemaining: '{{count, number}} dia restante',
  dayRemaining_plural: '{{count, number}} dias restantes',
  karmaStores: 'Lojas com Karma Cash',
  priceLeftToWithdraw: 'Falta apenas {{currency}}{{value, number}} para retirar suas recompensas!',
  fullPrice: '{{currency}}{{value, number}}.{{fraction, fraction}}',
  fraction: '.{{value, fraction}}',
  pendingCash: 'Valor Pendente',
  payouts: 'Pagamentos',
  connectWithPayPal: 'Conectar com PayPal',
  selectCause: 'Selecione Causa',
  dayBetweenPayouts: 'Valor pode ser retirado {{count, number}} dia após confirmação da compra.',
  dayBetweenPayouts_plural: 'Valor pode ser retirado {{count, number}} dias após confirmação da compra.',
  list: 'Lista:',
  emptyWallet: 'Sua carteira está vazia',
  startShoppingToEarn: 'Compre para receber recompensas!',
  withdraw: 'Retirar',
  yourDonations: 'Suas doações',
  outOfAmount: 'Sem {{currency}}{{value, number}}',
  gettingCloser: 'Você está perto!',
  canWithdrawEarnings: 'Você já pode retirar seus ganhos.',
  workedAt: 'Trabalhou {{date}}',
  copied: 'Copiado!',
  getCoupon: 'Obter cupom',
  shopNow: 'Compre já',
  karmaLogo: 'logotipo Karma',
  stores: 'Lojas',
  myLists: 'Minhas Listas',
  uppercase: '{{text, uppercase}}',
  capitalize: '{{text, capitalize}}',
  all: 'Tudo',
  lastWeek: 'Última semana',
  lastMonth: 'Último mês',
  lastThreeMonths: 'Últimos 3 meses',
  clickID: 'ID Clique:',
  date: 'Data',
  time: 'Hora',
  retailer: 'Lojista',
  orderID: 'ID Pedido',
  startShoppingToEarnKarmaCash: 'Compre para receber Karma Cash,',
  browseStores: 'explorar lojas',
  doNotHaveClicks: 'Você não teve nenhum clique neste período.',
  doNotHaveTransactions: 'Você não teve nenhuma transação neste período.',
  payPalAlt: 'PayPal',
  payPal: 'PayPal:',
  welcomeBonus: 'Bônus Boas-vindas!',
  karmaGives: 'Karma Gives!',
  karmaGivesRefund: 'Reembolso do Karma Gives!',
  completed: 'Concluído',
  notQualified: 'Não qualificado',
  pending: 'Pendente',
  cancelled: 'Cancelado',
  paid: 'Pago',
  empty: 'Vazio',
  expired: 'Expirado',
  discoverAndShop: 'Explorar e comprar',
  contactUs: 'Fale conosco',
  news: 'Novidades',
  faq: 'FAQ',
  aboutUs: 'Sobre nós',
  karmaItem: 'Item Karma',
  email: 'E-mail',
  congrats: 'Parabéns!',
  congratulations: 'Felicitações!',
  startSaving: 'Comece a Economizar',
  getKarmaButton: 'Obter Botão Karma!',
  connectNow: 'Conecte agora',
  saveThingsToBuy: 'Salve seus itens favoritos para comprar depois com Karma!',
  savedFirstItem: 'Você salvou o seu primeiro item!',
  getTheButton: 'Obtenha o Botão',
  savedFirstItem_plural: 'Você já salvou alguns itens!',
  takeMeBack: 'Quero voltar para',
  iWantToSeeOther: 'Eu quero ver outras <1>lojas suportadas</1>',
  neverMissProducts: 'Fique sabendo quando produtos entrarem em promoção ou voltarem ao estoque!',
  shareByEmail: 'Compartilhar por e-mail',
  whenIFindSmth: 'Quando acho algo que quero, salvo no Karma!',
  completeSignUp: 'Concluir cadastro',
  continueAndNotify: 'Continue e vamos avisar quando o preço cair.',
  addToList: 'Adicionar à Lista',
  chat: 'Chat',
  karmaCash: 'Karma Cash',
  clickToSaveItem: 'Clique para salvar um item',
  createListAndPressEnter: 'Crie lista e aperte "Enter"',
  confirmDeleteProduct: 'Quer mesmo excluir este produto?',
  shareOnFacebook: 'Compartilhar no Facebook',
  selectAll: 'Selecionar tudo',
  selectProp: 'Selecione {{name}}',
  delete: 'Excluir',
  fulfill: 'Realizado',
  mute: 'Desativar som',
  anyPriceChange: 'Qualquer mudança de preço',
  priceOff: '{{value, number}}% de desconto',
  notifyWhen: 'Avise quando',
  tweetOnTwitter: 'Tuitar no Twitter',
  shareProduct: 'Compartilhar Produto',
  markAsFulfilled: 'Marcar como Realizado',
  unmuteNotifications: 'Ativar Áudio das Notificações',
  muteNotifications: 'Silenciar Notificações',
  priceEvolution: 'Evolução do Preço',
  logOut: 'Sair',
  twitter: 'Twitter',
  facebook: 'Facebook',
  instagram: 'Instagram',
  whatsapp: 'WhatsApp',
  messenger: 'Messenger',
  privacyPolicy: 'Política de Privacidade',
  confirmDelete: 'Quer mesmo excluir estes produtos?',
  termsAndConditions: 'Termos e Condições',
  logosProvidedBy: 'Logos fornecidos por {{name}}',
  copyright:
    'Copyright © {{year, clearNumber}} Karma Shopping Ltd., de nome comercial Karma. Todos os direitos reservados.',
  noNotifications: 'Sem notificações',
  clearAllNotifications: 'Apagar Todas Notificações',
  areYouSureRemoveNotifications: 'Quer mesmo remover todas as notificações?',
  deleteProducts: 'Excluir Produtos',
  no: 'Não',
  yes: 'Sim',
  headerStores: 'Karma | Lojas',
  clearNotification: 'Apagar notificações',
  clear: 'Apagar',
  bell: 'sino',
  remindInMinute: 'Lembrar daqui a {{value, number}} minuto',
  remindInMinute_plural: 'Lembrar daqui a {{value, number}} minutos',
  remindInHour: 'Lembrar daqui a {{value, number}} hora',
  remindInHour_plural: 'Lembrar daqui a {{value, number}} horas',
  retailers: 'Lojistas',
  claimKarmaCash: 'Obtenha até {{percent, number}}% de Karma Cash',
  clickOnClaimButton: 'Clique no botão "Obtenha até {{percent, number}}% de Karma Cash".',
  goShopping: 'Vá às Compras',
  rackUpCash: 'Acumule Dinheiro',
  getPaidDirectly: 'Seja Pago Diretamente',
  earnRealMoney: 'Ganhe dinheiro de verdade de volta em literalmente todas as compras.',
  trackYourEarnings: 'Acompanhe seus ganhos e faça retiradas em todas as estações.',
  ordinalNumber: '{{value, fraction}}',
  relatedStores: 'Lojas Relacionadas',
  defaultWhatHappensNext:
    'Para ganhar Karma Cash automaticamente na próxima compra, clique em um dos nossos lojistas qualificados com Karma Cash no Karma, onde você pode continuar comprando como de costume! Lembre-se: você só ganha Karma Cash visitando o lojista qualificado através do app ou do painel do Karma (incluindo notificações push) ou interagindo com a extensão para navegador do Karma em desktop/celular antes da compra. Depois que o lojista tiver verificado sua compra, o Karma confirmará seus ganhos na sua carteira Karma.',
  defaultTermsAndConditions:
    'Não há Karma Cash na compra de cartões-presente ou em qualquer porção de uma compra que tenha sido paga com um cartão-presente, crédito de loja ou pontos de fidelidade ou outro programa de pontos. O uso de códigos promocionais não listados no site do Karma pode anular o Karma Cash. Produtos ou categorias específicas podem estar sujeitas a restrições adicionais.',
  whatHappensNext: 'E agora?',
  about: 'Sobre',
  readMore: 'Leia mais',
  frequentlyAskedQuestions: 'Perguntas frequentes',
  loadMore: 'Carregar mais',
  questionButton: 'botão de interrogação',
  headerKarmaCash: 'Karma | Recompensas',
  recommended: 'Recomendado',
  highToLow: 'Maior ao menor',
  lowToHigh: 'Menor ao maior',
  sortBy: 'Selecionar por:',
  exploreStores: 'Explorar Lojas',
  weHaveNotFoundStores: 'Não encontramos nenhuma loja de acordo com sua busca',
  weHaveNotFoundCoupons: 'Não encontramos nenhum cupom de acordo com sua busca',
  headerApps: 'Karma | Aplicativos',
  karmaApps: 'Tenha um bom karma em todo lugar.',
  stayUpdated:
    'Mantenha-se atualizado(a) em qualquer aparelho. Nosso assistente de compras digitais economiza dinheiro para você em mais de {{number, number}}K lojas.',
  getFaster: 'Tenha uma experiência mais rápida e suave baixando o Karma no seu Mac.',
  headerProfile: 'Karma | Perfil',
  headerWallet: 'Karma | Carteira',
  totalBalance: 'Saldo Total',
  editYourAccount: 'Edite sua conta',
  emailAddress: 'Endereço de E-mail',
  enterEmailAddress: 'Inserir o e-mail',
  enterEmail: 'Inserir E-mail',
  enterEmailLower: 'Inserir e-mail',
  phoneNumber: 'Celular',
  enterPhoneNumber: 'Inserir o celular',
  fullName: 'Nome Completo',
  enterFullName: 'Inserir nome completo',
  enterPlaceholder: 'Inserir {{name}}',
  birthday: 'Nascimento',
  year: 'Ano',
  month: 'Mês',
  day: 'Dia',
  gender: 'Gênero',
  language: 'Idioma',
  currency: 'Moeda',
  multiCurrency: 'Multimoeda',
  male: 'Masculino',
  female: 'Feminino',
  notWantShare: 'Não quero divulgar',
  mobilePush: 'Notificação mobile',
  webPush: 'Notificação web',
  disconnectFromPayPal: 'Desconectar do PayPal',
  loginAsUser: 'FUT',
  selectYearAndMonth: 'Selecione primeiro o ano e mês',
  notSureWhereToStart: 'Não sabe por onde começar?',
  youDidntSaveItems: 'Você ainda não salvou nenhum item.',
  searchItems: 'Buscar Itens',
  headerDashboard: 'Karma | Painel',
  headerSales: 'Karma | Ofertas',
  couldNotFindStores: 'Ops, não encontramos nenhuma venda',
  karmaGivesValue: 'U$ {{value, number}}',
  karmaGivesModalTitle: 'Aqui estão U$ {{value, number}} GRÁTIS para você começar',
  hereIsABitOfGoodKarma: 'Aqui está um karma positivo por ter se cadastrado!',
  collectAndStartSaving: 'Colecione e Comece a Economizar',
  termsAndConditionsAppersant: 'Termos e Condições',
  mayApply: 'podem ser aplicados',
  termsAndConditionsError: 'Primeiro, concorde com os nossos termos e política de privacidade.',
  validEmailError: 'Digite um e-mail válido.',
  completeSignUpCapital: 'Concluir Cadastro',
  or: 'OU',
  password: 'Senha',
  joinUs: 'Junte-se a nós',
  reviewsPlus: '{{amount, number}}k+ avaliações',
  logIn: 'Entrar',
  termsAgreement: 'Ao se cadastrar, você concorda com nossos <1>Termos</1> e nossa <3>Política de Privacidade</3>',
  termsAgreementWithCheckbox: 'Eu concordo com os <1>Termos e Condições</1> e com a <3>Política de Privacidade</3>',
  createANewList: 'Criar Nova Lista',
  listCreateBlickError: 'Nome da lista não pode estar em branco',
  enterListName: 'Digite um nome da lista',
  listDescriptionOptional: 'Descrição da Lista (opcional)',
  listName: 'Nome da Lista',
  enterDescriptionForList: 'Inserir descrição da lista',
  save: 'Salvar',
  deleteList: 'Excluir Lista',
  areYouSureRemoveList: 'Quer mesmo remover esta lista?',
  editList: 'Editar Lista',
  listNameCanNotBeBlanck: 'Nome da lista não pode estar em branco',
  shareList: 'Compartilhar Lista',
  notAMemberCreateAccount: 'Não tem conta? <1>Criar conta!</1>',
  backToLogin: 'Voltar ao login',
  enterYourEmail: 'Inserir e-mail',
  resetPassword: 'Redefinir Senha',
  forgotPasswordMessage: 'Em alguns minutos, você receberá um e-mail com instruções sobre como redefinir sua senha.',
  forgotPassword: 'Esqueceu a Senha?',
  reCaptchaMessage:
    'Este site é protegido por reCAPTCHA e a <1>Política de Privacidade</1> e os <3>Termos de Serviço</3> do Google se aplicam',
  pleaseScan: 'Favor buscar',
  saveAnItem: 'Salvar um item',
  areYouSureFulfilled: 'Quer mesmo marcar estes produtos como concluídos?',
  muteTagDesc: 'Quer mesmo silenciar as notificações destes produtos?',
  muteTagTitle: 'Silenciar Notificações',
  ok: 'OK',
  canAccessEarningsInPayPal: 'Você pode acessar seus ganhos pela conta do PayPal',
  ccpaTitle:
    'Lei de Privacidade do Consumidor da Califórnia (CCPA), conforme alterado pela Lei de Direitos de Privacidade da Califórnia (CPRA)',
  ccpaText:
    ' Ao enviar este formulário, eu exerço meu direito de recusar qualquer venda de minhas informações pessoais.',
  submit: 'Enviar',
  joinKarmaAndGetRewards: 'Entre no Karma e ganhe {{stake, number}} recompensas em {{name}}',
  doNotMissOut: 'Não perca. Você pode economizar neste produto!',
  signUpAndGetMoney: 'Inscreva-se e receba U$ {{value, number}}',
  itemAlert: 'Há um Alerta de Item!',
  accessKarmaCash: 'Acesse seu Karma Cash',
  youEarnedKarmaCash: 'Você acaba de ganhar Karma Cash!',
  verifyYourPaypalAccount: 'Verifique sua conta do PayPal<1/>',
  weHaveSentAnEmailTo: 'Enviamos um e-mail para',
  'Fall Favorites': 'Favoritos do Outono',
  'Karma Cash': 'Karma Cash',
  'Clothing & Luxury': 'Vestuário e Luxo',
  'Home Decor & Furniture': 'Decoração e Móveis',
  'Electronics & Office Supplies': 'Eletrônicos e Escritório',
  Luxury: 'Luxo',
  Clothing: 'Roupas ',
  'Handbags & Shoes': 'Bolsas e Sapatos',
  'Jewelry & Watches': 'Joias e Relógios',
  Accessories: 'Acessórios',
  'Beauty Essentials': 'Essenciais de Beleza',
  'Health & Wellness': 'Saúde e Bem-estar',
  Lifestyle: 'Estilo de Vida',
  'Sports & Outdoors': 'Esportes e Ar Livre',
  Travel: 'Viagem',
  'Electronics & Office supplies': 'Eletrônicos e Escritório',
  'Entertainment & Online Services': 'Entretenimento e Serviços On-line',
  Education: 'Educação',
  'Finance & FinTech': 'Finanças e FinTech',
  'Pet Supplies': 'Pets',
  'Food & Beverages': 'Comidas e Bebidas',
  Men: 'Homem',
  Women: 'Mulher',
  Unisex: 'Unissex',
  Kids: 'Infantil',
  Baby: 'Bebê',
  notifications: 'Notificações',
  changesSaved: 'Alterações salvas!',
  headerRetailer: 'Karma | Lojista',
  headerRedirect: 'Karma | Redirecionando...',
  karma: 'Karma',
  goBackToKarma: 'Voltar ao Karma',
  authentication: 'Autenticação',
  authSuccess: 'Parabéns! Você fez login com sucesso.',
  authFailure: 'Ops, não pudemos autorizá-lo. Tente mais tarde.',
  mayNotQualify: 'Alguns produtos não são qualificados para Karma Cash',
  checkOutRetailers: 'Confira nossos lojistas que aceitam Karma Cash.',
  headerHowItWorks: 'Karma | Como Funciona',
  accept: 'Aceitar',
  cookies:
    'O Karma usa cookies para garantir que você veja o que achamos que você mais vai gostar. <1>Clique aqui</1> para ler nossa política completa.',
  headerError: 'Karma | Erro',
  pageNotFound: 'Página Não Encontrada',
  pageDoesNotExist: 'Ops, esta página não existe.',
  backHome: 'Voltar para Início',
  startShopping: 'Começar a Comprar',
  illustration: 'Ilustração',
  dashboard: 'Painel de Controle',
  allRightsReserved: 'Todos os direitos reservados.',
  product: 'Produto',
  disclaimer:
    'Karma Shopping Ltd., de nome comercial Karma. A Karma não possui nem pretende possuir a propriedade de marcas registradas de terceiros. As marcas registradas de terceiros que estejam visíveis ou acessíveis, direta ou indiretamente, pela plataforma Karma, não necessariamente têm uma relação com a Karma, com seus associados terceirizados ou com os provedores de serviços da Karma.',
  help: 'Ajuda',
  team: 'Equipe',
  howItWorks: 'Como funciona',
  supportedStores: 'Lojas Parceiras',
  company: 'Empresa',
  planYourPurchase: 'Planeje sua próxima compra com nosso app gratuito!',
  itSitsInBrowser: 'Ele fica no seu navegador enquanto você explora suas lojas favoritas.',
  seeSmthYouLike:
    'Viu algo que gostou? Clique no botão para salvar na sua lista e o Karma vai acompanhar o preço e a disponibilidade para você.',
  youWillGetNotified:
    'Você será notificado(a) instantaneamente quando algum dos seus produtos entrar em promoção, baixar o preço ou voltar ao estoque na sua cor e tamanho de preferência.',
  everythingYouWant:
    'Tudo que você quiser comprar estará em um lugar conveniente, para que possa se organizar e parar de lidar com várias listas de desejos.',
  headerContactUs: 'Karma | Contate-nos',
  needToGetInTouch: 'Quer entrar em contato?',
  dropALine: 'Entre em contato',
  comeHangOut: 'Enturme-se conosco',
  joinOurFacebookCommunity: 'Entre na nossa comunidade do Facebook',
  followOnInstagram: 'Siga-nos no Instagram',
  headerAboutUs: 'Karma | Sobre Nós',
  addTheItems:
    'Adicione os produtos que está comprando — você pode personalizar até o tamanho e a cor — e o app notificará você quando ele entrar em promoção.',
  jonathanSay:
    'Jonathan: Enquanto os lojistas têm usado IA por anos, agora é o cliente que aproveita o aprendizado de máquina e as ferramentas de automação para ter mais eficiência.',
  justDownload:
    'É só baixar um "botão" e clicar nele quando estiver em um produto que quiser salvar na lista. No celular, você pode "compartilhar" produtos diretamente com as mesmas listas.',
  ifYouFind:
    'Se você encontrar um produto on-line fora do orçamento, você pode definir um alerta de promoção para ser notificado(a) quando o preço baixar.',
  onlyAHandful: 'Poucas empresas que oferecem alertas de promoção ainda existem, incluindo a Karma.',
  alwaysBuy: 'Compre algo sempre na promoção com Karma',
  headerCareers: 'Karma | Carreiras',
  getInTouch: 'Entre em contato conosco.',
  shop: 'Loja',
  weCouldNotFindResults: "Não encontramos resultados para '{{name}}'",
  couldNotFindResults: 'Não encontramos nenhum resultado',
  shopAllStores: 'Compre em todas as lojas parceiras',
  popularStores: 'Lojas populares',
  shops: 'Lojas',
  headerDiscoverAndShop: 'Karma | Descubra e compre os últimos produtos salvos',
  prev: 'Anterior',
  next: 'Próximo',
  headerDemo: 'Karma | Demo',
  remindLater: 'Lembre mais tarde',
  getTheKarmaButton: 'Obtenha o Botão Karma',
  installedKarma: 'Você instalou o Karma com sucesso!',
  settingUpAccount: 'Agora, vamos começar configurando sua conta.',
  signUpNow: 'Inscreva-se agora',
  logInNow: 'Entre agora',
  signUpLater: 'Inscreva-se depois',
  wePartnerWith: 'Fazemos parcerias com mais de {{number, number}}K+ lojas on-line!',
  pickStoresToSee: 'Escolha uma das lojas abaixo para ver como funciona:',
  leaveKarma: 'Note que você deixará o Karma e irá para a página da loja para uma experiência completa.',
  searchFavoriteStores: 'Busque Suas Lojas Favoritas',
  clickBelowToStart: 'Clique abaixo para começar',
  chooseOneOfTheOptions: 'Escolha uma das opções abaixo',
  clickToBegin: 'Clique na barra de busca para começar',
  preferNotToSay: 'Prefiro não informar',
  done: 'Concluído',
  headerUnsubscribe: 'Karma | Cancelar Inscrição',
  changeMind: 'Mudou de ideia?',
  resubscribeList: 'Recadastrar na lista de envios.',
  resubscribeList_plural: 'Recadastre na lista de envios aqui.',
  changePreferencesAtAnyTime: 'Você também pode suas preferências a qualquer momento nas<1>configurações de conta.</1>',
  preferencesUpdated: 'Atualizamos suas preferências de e-mail.',
  sorryToSeeYouGo: 'Sentiremos sua falta!',
  reSubscribed: 'Você se recadastrou',
  allowHours: 'Favor aguardar até {{hours, number}} horas para que as mudanças tenham efeito.',
  missWhenYouGone:
    'Sentiremos sua falta! Favor aguardar até {{hours, number}} horas para que as mudanças tenham efeito.',
  headerDisablingSubscribe: 'Karma | Cancelando Inscrição',
  noLongerAlerts: 'Você não receberá mais alertas para este produto.',
  turnAlertsBack: 'Reativar os alertas.',
  moveAnimations: 'Animação de movimento do botão',
  headerExtensionDeleted: 'Karma | Extensão excluída',
  areSorryToSeeYouGo: 'Sentimos muito por ver você partir!',
  youHaveGotInYourWallet: 'Você tem U$ {{value}} na sua Carteira.',
  toWithdraw: ' para retirar seus ganhos!',
  toContinue: ' para continuar ganhando recompensas!',
  reasonUninstalled: 'Por favor, conte-nos por que você desinstalou:',
  getInTouchBtn: 'Entre em contato',
  howWeCanIncrease: 'Você pode aumentar seu reconhecimento e impulsionar vendas anunciando no Karma destas formas',
  helpingShoppers:
    'Ajudando compradores a descobrirem marcas que eles vão amar é uma de nossas maiores prioridades como um assistente de compras pessoal.',
  whyWeOfferBrands:
    'É por isso que oferecemos às marcas que consistentemente entregam uma alta satisfação do cliente várias formas de se conectarem com nosso público. Você atinge as metas de sua campanha e nossa clientela conhece sua nova marca favorita.',
  winWin:
    'Nós oferecemos às marcas que consistentemente entregam uma alta satisfação do cliente várias formas de se conectarem com nosso público. Você atinge as metas de sua campanha e nossa clientela conhece sua nova marca favorita — todo mundo ganha!',
  getFeaturedRight: 'Apareça em destaque no nosso app e no painel da extensão do navegador',
  getFeaturedDashboard: 'Apareça em destaque no painel do Karma',
  putYourHand:
    'Tenha presença, produtos ou promoções no lugar exato para onde nossos usuários vão quando estão prontos para comprar. Podemos criar vários conteúdos de marca para promover o tráfego para onde você precisa que ele vá.',
  exactPlace: 'Coloque sua marca no lugar exato em que nossos usuários vão quando querem comprar.',
  dedicatedInAppPlacement: 'Posicionamento dedicado no aplicativo',
  dashboardFeaturedBanners: 'Banners em Destaque do Painel',
  dashboardPopUpMessages: 'Mensagens Pop-Up do Painel',
  customEmailsAndNewsLetters:
    'Customize e-mails e o posicionamento em informativos para {{subscribers, number}}+ inscritos',
  customEmails: 'Customize E-mails para {{subscribers, number}}+ Inscritos',
  getMoreEyes:
    'Chame mais atenção para sua última oferta, código de cupom ou linha de produtos com um e-mail ou posicionamento dedicado em informativos para compradores.',
  putYourBrand: 'Coloque sua marca no lugar exato em que nossos usuários vão quando querem comprar.',
  newsletterInclusion: 'Inclusão no Informativo',
  dedicatedCustomEmails: '{{percent, number}}% de E-mails Personalizados Dedicados',
  bannerPlacementInEmails: 'Posicionamento de Banner nas Notificações por E-mail',
  pushNotificationsToShoppers: 'Notificações push para {{shoppers, number}}+ compradores',
  pushNotificationsGiveTouchPoint:
    'As notificações push dão à sua campanha outro ponto de interação e promovem altas taxas de cliques e acessos.',
  featureBrandClients: 'Marcas em Destaque',
  growingPerMonth: 'Crescendo +{{number, number}}k por mês',
  members: 'Membros',
  percent: '{{value, number}}%',
  kindOfReachYouExpect: 'Que tipo de alcance você espera?',
  ourAudience: 'Nosso Público',
  tabIntoNewSource: 'Acesse uma nova fonte de clientes qualificados para sua próxima campanha',
  tellUsAboutYourCompany:
    'Conte-nos um pouco sobre sua empresa e suas metas e mostraremos como podemos ajudar você a superá-las.',
  openAndTap: 'Abra o Safari e vá à página do produto que você deseja salvar e clique no botão "Compartilhar"',
  shareIllustration: 'Compartilhar ilustração',
  tapMore: 'Clique no botão "Mais" no lado direito do painel.',
  moreIllustration: 'Mais ilustração',
  enableIllustration: 'Habilitar ilustração',
  tapEdit: 'Clique no botão "Editar" no topo do painel.',
  enableApp:
    'Habilite o Karma e clique no botão "adicionar" para adicioná-lo aos seus Favoritos e, então, clique em "Pronto".',
  goBackToTheProduct:
    'Volte à página do produto, clique no botão "Compartilhar" e, então, clique no botão "Karma" para salvar o produto.',
  recommendedBy: 'Recomendado por',
  ourMembersSavingMoney: 'Nossos membros ❤️ economizar com o Karma. Aqui estão os motivos.',
  addToBrowser: 'Adicione ao {{browser}} - É gratuito',
  addToBrowserBtn: 'Adicione ao {{browser}}',
  planPurchases: 'Planeje suas próximas compras',
  karmaMakesItEasy: 'Com Karma fica fácil organizar suas próximas compras em um só lugar.',
  buyAtRightTime: 'Compre na hora certa',
  automaticallyAlert: 'Karma te avisa automaticamente quando é a hora certa de comprar.',
  cashOnVacation: 'Karma Cash em suas próximas férias',
  karmaPartners: 'Parceiros Karma com vários sites de viagens e hotéis',
  headerRewards: 'Karma | Melhores Cupons e Recompensas Financeiras',
  mission: 'Nossa Missão',
  empowerYou: 'Ajudar você a tomar a decisão certa na hora certa quando comprar on-line.',
  weAreSeriousAboutPrivacy: 'Nos preocupamos com a sua privacidade e segurança',
  weDoNotSellData: 'Não vendemos os seus dados',
  karmaIsSerious: 'O Karma se preocupa com seus dados e nunca os venderemos para terceiros. Nunca.',
  karmaIsSecure: 'Karma é seguro',
  weUseBankSecurity:
    'Nós usamos segurança de nível bancário (criptografia de {{bit, number}}-bits) para garantir que seus dados estejam seguros e protegidos.',
  helpsSaveMore: 'Karma te ajuda a economizar!',
  alertMe: 'Alerte-me',
  buyWithKarmaEarn: 'Compre com Karma - Ganhe U$ {{number, number}}',
  productSaved: 'Parabéns! Este produto foi salvo na sua lista de itens. Você será notificado quando o preço cair.',
  willApplyTheBestCoupons: 'O Karma aplicará automaticamente o melhor cupom para sua compra!',
  downloadAndAutoApply: 'Baixe o Karma e Aplique Automaticamente',
  whatIsKarma: 'O que é o Karma?',
  claimUpToRewards: 'Ganhe até {{number, number}}% de Recompensas em Dinheiro',
  youMightAlsoLike: 'Você talvez goste',
  weAreSorry: 'Desculpe, algo deu errado e a página não pode ser exibida.',
  tryToReloadPage: 'Por favor, tente recarregar a página.',
  Color: 'Cor',
  Size: 'Tamanho',
  latestCoupons: '{{name}} | Últimos Cupons e Cashback do Karma em {{year, clearNumber}}',
  neverPayFullPrice: 'Não pague mais o preço cheio. Encontre Cupons e Cashback do Karma para {{name}}',
  neverPayFullPriceForTag: 'Nunca mais pague o preço cheio por {{name}}',
  saveMoneyWhenShoppingAt: 'Economize Dinheiro Ao Comprar Na {{name}}. Cadastre-se no Karma Gratuitamente',
  saveMoneyWhenShoppingFor: 'Economize Dinheiro Ao Comprar {{name}}. Cadastre-se no Karma Gratuitamente',
  tagByBrand: 'Karma | {{tag}} por {{brand}}',
  typeToSearch: 'Digite para pesquisar',
  getUSDWhenJoinToday: 'Receba U$ {{value, number}} Cadastrando-se no Karma Hoje!',
  buyProduct: 'Comprar Produto',
  userWishList: 'Karma | lista de compras {{name}}',
  byWhom: 'por',
  copiedToClipboard: 'Copiado para a área de transferência!',
  send: 'Enviar',
  cause: 'Selecione a causa:',
  aCause: 'Selecione uma causa:',
  close: 'Fechar',
  selectThisCause: 'Selecionar esta causa',
  habitatForHumanity:
    '{{name}} concentra-se na redução da pobreza no longo prazo através de moradias sociais acessíveis. Sua missão é construir um ambiente seguro, onde famílias e comunidades possam prosperar',
  casaDescription:
    'A National CASA/GAL Association apoia e promove advocacia voluntária designada por tribunal de modo que toda criança que tenha sofrido abuso ou negligência possa ficar segura, ter um lar permanente e a oportunidade de prosperar',
  endHomelessnessDescription:
    'A Aliança Nacional para {{name}} é uma organização não governamental determinada a prevenir e eliminar a falta de habitação nos Estados Unidos',
  pathDescription: 'PATH está ajudando pessoas, famílias e comunidades sem habitação a encontrar abrigo',
  feedingAmericaDescription: 'Feeding America é a maior organização nacional de combate à fome',
  mealsOnWheelsDescription:
    '{{name}} capacita programas comunitários locais a melhorar a saúde e a qualidade de vida dos idosos que atendem para que nenhum tenha fome ou fique isolado',
  actionAgainstHunger:
    '{{name}} é uma organização humanitária global que toma medidas decisivas contra as causas e efeitos da fome',
  supportMedicalAdvancement: 'Apoio ao avanço médico',
  saveChildsHeartDescription:
    "Save A Child's Heart presta serviços de saúde do coração, independentemente de raça, religião, gênero, nacionalidade ou situação financeira",
  su2c: 'Stand Up To Cancer (SU2C) financia e desenvolve os tratamentos de câncer mais modernos e promissores para ajudar pacientes',
  drWithoutBorders: '{{name}} é um movimento global independente que presta auxílio médico onde é mais necessário',
  activeMinds:
    '{{name}} é a principal organização sem fins lucrativos do país, que promove conscientização de saúde mental e educação para jovens adultos',
  cleanAirTaskForceDesc:
    '{{name}} estimula mudanças necessárias em tecnologias e políticas para chegar a um planeta com zero emissão e alta energia a um custo acessível',
  wwfDesc:
    'A missão da {{name}} é preservar a natureza e reduzir as ameaças mais urgentes à diversidade da vida na Terra',
  waterkeepsDesc:
    '{{name}} luta por água limpa. A Waterkeeper Alliance trabalha para garantir que toda comunidade ao redor do mundo tenha água potável, própria para pescar e nadar',
  xprice:
    'Todos os dias, a XPrize trabalha para criar um mundo melhor onde todos tenham acesso a água limpa, alimentos nutritivos, moradia acessível, educação eficaz, cuidados médicos da melhor qualidade e energia abundante não poluente',
  globalActDesc: '{{name}} trabalha para pôr um fim à exploração sexual e comercial',
  pcaa: '{{name}} trabalha duro para concretizar nossa visão de um mundo onde todas as crianças cresçam felizes, saudáveis e preparadas para ter sucesso em famílias e comunidades solidárias',
  endCAN:
    'A EndCAN promove a conscientização dos impactos do abuso na saúde física, mental e pública, com a missão de pôr um fim no abuso e negligência infantis',
  futuresDesc:
    'FUTURES oferece programas, políticas e campanhas inovadores que capacitam pessoas e organizações trabalhando para eliminar a violência contra as mulheres e as crianças ao redor do mundo',
  thornDesc: '{{name} constrói tecnologias para defender crianças do abuso sexual.',
  'Eradicate Homelessness': 'Erradicar a falta de habitação',
  'Help End Hunger': 'Ajudar a eliminar a fome',
  'Support Medical Advancement': 'Promover o avanço médico',
  'Combat Climate Change': 'Combater as mudanças climáticas',
  'Act To End Abuse': 'Agir para eliminar o abuso',
  itemsYouSaved: 'Itens salvos por menos',
  beta: 'BETA',
  alsoAvailableAt: 'Disponível também em',
  atName: 'em {{name}}',
  bestPrice: 'Melhor Preço',
  passwordWasSuccessfullyReset: 'A senha foi redefinida',
  paidWith: 'Pago com',
  infoFirstNumber: '{{number, number}}{{unit}}+',
  infoFirstTitle: 'Membros',
  infoSecondNumber: '{{number, number}}{{unit}}+',
  infoSecondTitle: 'Visitas Mensais',
  infoThirdNumber: '{{number, number}}{{unit}}+',
  infoThirdTitle: 'Lista de E-mail',
  infoFourthNumber: '{{value, number}}%',
  infoFourthTitle: 'Millenials',
  infoFifthNumber: '{{value, number}}%',
  infoFifthTitle: 'Feminino',
  infoSixthNumber: '{{value, number}}%',
  infoSixthTitle: 'Localizado nos EUA',
  infoSeventhNumber: '{{number, number}}{{unit}}+',
  infoSeventhTitle: 'Permitir notificações push no celular',
  infoEighthNumber: '{{number, number}}{{unit}}+',
  infoNinthPreNumber: '',
  infoNinthNumber: '${{number, number}}{{unit}}+',
  infoNinthTitle: 'Serão usados pelos usuários até o fim de {{year, clearNumber}}',
  karmaIsAlwaysWithYou: 'O Karma está sempre com você nas compras',
  saveItemsToBuy: 'Salves itens e vamos te dizer quando comprar',
  weDoTheWork: 'Fazemos o trabalho para encontrar os melhores cupons',
  earnRealCashThatAdds: 'Ganhe dinheiro real que se acumula na sua carteira',
  setUpStrongPassword: 'Defina uma senha forte',
  settingEmailAccount:
    'A última etapa para economizar com Karma? Cadastre-se para podermos te enviar alertas importantes sobre os seus Itens, como promoções.',
  settingPasswordAccount:
    'A senha deve conter no mínimo {{number, number}} caracteres e não deve incluir suas credenciais de e-mail.',
  countWaysToUse: '{{number, number}} jeitos de usar Karma',
  goBack: 'Voltar',
  pleaseChooseStrongerPassword: 'Escolha uma senha mais segura',
  pleaseEnterValidEmailAddress: 'Insira um endereço de e-mail válido',
  illSignUpLater: 'Inscrever-se depois',
  enterYourEmailAddress: 'Insira seu e-mail e lhe enviaremos um link para redefinir sua senha.',
  headerOnboardingIosExtension: 'Karma | Extensão Integração IOS',
  weWillAutomaticallyApplyCouponsWhereverYouShop: 'Aplicaremos cupons automaticamente onde quer que você compre',
  continue: 'Continuar',
  changeLanguage: 'Mudar Idioma',
  selectedLanguage: 'Idioma Selecionado',
  headerMuteItem: 'Karma | Desativar Item',
  itemHasBeenMuted: 'Pronto! Este item foi desativado.',
  toUnmuteItem: 'Ativar som deste item:',
  headToMyItems: 'Vá para a guia "Meus itens"',
  clickThreeButtonsInItem: 'Clique nos três botões no canto inferior direito do item',
  clickUnmute: 'Clique “Ativar”',
  weveJustRaisedInFunding:
    'Arrecadamos ${{value, number}}M de financiamento série A para ajudá-lo a comprar com inteligência!',
  weveJustRaised: 'Arrecadamos ${{value, number}}M',
  landingReview1: 'Ótimo app! Fui avisado sobre uma promoção e economizei muito!',
  landingReview2: 'Economizei ${{value, number}} logo na primeira compra. Impressionante!',
  landingReview3: 'Um app muito útil; amo os alertas e recompensas.',
  landingReview4: 'Ainda não vi nenhuma outra experiência de compra melhor!',
  landingReview5: 'Poupei {{value, number}}%! Não saberia sobre a promoção se não fosse ele!',
  landingReview6: 'Fácil seguir produtos que você adora... Superrecomendado...!',
  landingReviewAuthor1: 'Justin Nguyen',
  landingReviewAuthor2: 'Amy Ellis',
  landingReviewAuthor3: 'Maude Aethelredd',
  landingReviewAuthor4: 'Patrick Slocum',
  landingReviewAuthor5: 'Michelle Findley',
  landingReviewAuthor6: 'Varsha Hulekal',
  auxiliaryDate: '{{date}}',
  resetMyPassword: 'Redefinir minha senha',
  repeatPassword: 'Repetir senha',
  continueAsGuest: 'Continuar como visitante',
  viewAt: 'Visualizar em {{- name}}',
  signUp: 'Inscrever-se',
  youMayAlsoLike: 'Você pode gostar de',
  buyOnShop: 'Compre em',
  viewOn: 'Ver em {{- name}}',
  quickView: 'Olhada rápida',
  newList: 'Nova lista',
  undo: 'Desfazer',
  productDeleted: 'Produto excluído',
  linkCopiedToClipboard: 'Texto copiado',
  wishlists: 'Desejados',
  headerPartnerships: 'Karma | Parcerias',
  letsJoinForces: 'Vamos unir forças',
  bePartOfTheSmartShoppingRevolution:
    'Junte-se à revolução das compras inteligentes. Colocaremos sua marca em frente a +de {{number, number}} milhões de compradores prontos para finalizar a compra.',
  seeKarmaInAction: 'Veja Karma em ação',
  discoverHowKarma:
    'Como soluções baseadas na automação e IA do Karma podem aprimorar compras, direcionando tráfego específico para suas ofertas.',
  stepUpYourSales: 'Aumente suas vendas',
  averageROAS: 'ROAS<br/>médio',
  increaseInAOV: 'Aumento<br/>no AOV',
  useKarmaToPlanTheirNextPurchase: 'Use Karma e planeje<br/>suas próximas compras',
  meetOurShoppers: 'Conheça nossos compradores',
  meetOurShoppersDescription:
    'Aproveite soluções de marketing orientadas ao desempenho e fidelize compradores com alta intenção de compra. Use nossas colocações multiplataforma e alcance usuários onde estiverem.',
  audienceInfoFirstNumber: '{{number, number}}{{unit}}+',
  audienceInfoFirstTitle: 'Usuários',
  audienceInfoSecondNumber: '{{number, number}}{{unit}}+',
  audienceInfoSecondTitle: 'Visitas mensais',
  audienceInfoThirdNumber: '{{number, number}}{{unit}}+',
  audienceInfoThirdTitle: 'Itens salvos',
  audienceInfoFourthNumber: '{{value, number}}%',
  audienceInfoFourthTitle: 'Usuários nos EUA',
  audienceInfoFifthNumber: '{{value, number}}%',
  audienceInfoFifthTitle: 'Gerações Z e Y',
  audienceInfoSixthNumber: '{{value, number}}%',
  audienceInfoSixthTitle: 'Compradoras',
  ourUsersLoveUs: 'Nossas usuárias nos amam!',
  partnershipsReview1:
    'Uma ótima extensão! Sou usuária há anos e é ótimo poder salvar um monte de produtos num só lugar - torna as compras on-line muito menos estressantes!',
  partnershipsReviewAuthor1: 'Mirna Ramos',
  partnershipsReview2:
    'Como alguém que compra de tudo on-line, digo honestamente que o Karma é o melhor amigo dos compradores por vários motivos...',
  partnershipsReviewAuthor2: 'Sandra Maria',
  partnershipsReview3:
    'Melhor app de compras na PlayStore. Tão fácil de usar. Salvo todos os meus itens de minhas lojas favoritas num só app! 👍🏻',
  partnershipsReviewAuthor3: 'Cecília Ribeiro',
  performanceBasis: 'Desempenho básico',
  fixedFee: 'Taxa fixa',
  thanksForSubmitting: 'Obrigado por enviar!',
  someoneFromOurPartnershipsTeamWillBeInTouchShortly: 'Nossa equipe de parcerias entrará em contatado em breve.',
  letsKickStartOurPartnership: 'Vamos iniciar nossa parceria!',
  emailOrPhoneNumber: 'E-mail ou telefone',
  retailerName: 'Varejista indicado',
  affiliateNetwork: 'Rede afiliada',
  budgetStructure: 'Estrutura orçamentária',
  budget: 'Orçamento',
  tellUsMore: 'Conte-nos mais',
  pleaseFillInAllRequiredFields: 'Preencha os campos obrigatórios',
  partnerships: 'Parcerias',
  weCannotSaveThisItem: 'Não podemos salvar este item <1/> pois essa loja não é aceita',
  theresCoupon: 'Tem um cupom!',
  yourItemIsOffWithCode: 'Seu item está com {{value, number}}% de desconto com o código {{code}} em {{- name}}.',
  muteAllNotification: 'Silenciar todas as notificações',
  unMuteAllNotification: 'Reativar áudio das notificações',
  notificationSettings: 'Configurações das notificações',
  deleteNotification: 'Excluir notificação',
  notificationDeleted: 'Notificação excluída',
  itemMuted: 'Item silenciado',
  itemUnMuted: 'Áudio reativado',
  priceDrop: 'Queda de preço',
  superDeal: '🔥 Superoferta',
  notSureWhenToBuy: 'Não sabe quando comprar? Crie uma conta gratuita e vamos lhe dizer quando houver uma promoção.',
  skip: 'Pular',
  tcMayApply: 'Sujeito a T&Cs',
  earnKarmaCash: 'Ganhe até {{percent, number}}% de Karma Cash.',
  saveToKarma: 'Salvar item no Karma',
  getKarmaToTrackItem: 'Obtenha o Karma para acompanhar o tem',
  itemSaved: 'Item salvo!',
  youCanNowFindItem: 'Você já pode encontrar o item no seu Painel do Karma.',
  getKarmaExtension: 'Baixe a extensão do Karma para <1/> acompanhar este item imperdível',
  notifyYou: 'Vamos avisar quando for a melhor hora de comprar!',
  downloadTheExtension: 'Baixar a extensão',
  outOfStock: 'Esgotado',
  invalidEmailError: 'E-mail inválido',
  collapse: 'Recolher',
  expand: 'Expandir',
  lists: 'Listas',
  organizeYourItemsIntoWishListsWithJustAClick: 'Organize seus itens em listas de desejos com apenas um clique.',
  newItem: 'Novo item',
  helpCenter: 'Central de Ajuda',
  priceHighToLow: 'Preço Maior para Menor',
  priceLowToHigh: 'Preço Menor para Maior',
  sale: 'Promoção',
  filterBy: 'Filtrar por:',
  karmaExclusive: 'Exclusivo do K',
  noItemsFound: 'Nenhum item encontrado',
  select: 'Selecione',
  likeSneakersOrBlackFriday: "Como 'Tênis' ou 'Black Friday'",
  updateList: 'Atualizar lista',
  firstName: 'Nome',
  lastName: 'Sobrenome',
  enterFirstName: 'Digite o nome',
  enterLastName: 'Digite o sobrenome',
  recentSearches: 'Pesquisas recentes',
  addYourFavoriteStores: 'Adicione suas lojas favoritas',
  toAccessTheirLatestCoupons: 'para acessar os cupons mais recentes',
  logosProvidedByClearbit: 'Logotipos fornecidos pela Clearbit',
  dateOfBirth: 'Data de nascimento',
  weHaveEmailedYouALinkToResetYourPassword: 'Enviamos um link por e-mail para redefinir sua senha.',
  didNotGetIt: 'Não recebeu?',
  profile: 'Perfil',
  promotionsAndActivity: 'Promoções e atividades',
  couponsForFavoriteStores: 'Cupons para lojas favoritas',
  accountActivity: 'Atividade da conta',
  powerShopper: 'Comprador power? 💪',
  enterLinkToAnyItemPage: 'Digite um link para qualquer página de item',
  saveStuffWithZeroFuss: '<0>Baixe a extensão de navegador </0> e salve coisas sem complicações.',
  viewMore: 'Ver mais',
  tooHotToMiss: 'Top demais para perder',

  // WebsiteLandingKarmaOld
  joinShoppers: 'Junte-se a mais de {{number, number}} milhões de compradores',
  waysToUse: 'Formas de usar o Karma',
  youCanUseKarma:
    'Você pode usar o Karma de várias formas, como se certificar de conseguir o melhor preço na hora certa, além de planejar suas próximas compras. Aqui estão alguns exemplos que recomendamos:',
  livesInBrowser: 'Karma fica no seu navegador',
  browserExtension: 'A extensão do Karma vai com você onde você fizer compras on-line.',
  helpsYouPlan: 'Ajuda a planejar suas próximas compras',
  notReadyToBuyJust:
    'Não quer comprar agora? Salve para mais tarde com a extensão de navegador Karma e compre com mais consciência. Você pode acompanhar todos os itens salvos em um só lugar. ',
  automaticallyFindsCouponCodes: 'Encontra cupons automaticamente',
  findsAndAppliesCodes:
    'O Karma encontrará e aplicará automaticamente o melhor cupom para que você tenha sempre o melhor valor.',
  soYouCanBuyAtTheRightTime: 'Para que você possa comprar na hora certa',
  usesCleverTechnologies:
    'O Karma usa tecnologia inteligente que monitora a queda dos preços ou quando a peça volta ao estoque, assim você pode comprar na hora certa. ',
  notOnlySave:
    'Além de ajudar a economizar, O Karma também faz você ganhar dinheiro! Sem pontos. Sem truques. Você pode ganhar dinheiro real sempre que comprar algo.',
  makeImpact: 'Cause um impacto!',
  donate:
    'Compre como costuma comprar e veja como o Karma retribui. A cada compra de mais de U$ {{over, number}}, doamos U$ {{dollars, number}} a uma causa da sua escolha.',
  accessToOffers: 'Acesso a ofertas exclusivas',
  accessItems:
    'Acesse seus itens salvos no mesmo lugar, descubra ofertas exclusivas e veja as tendências no mundo com o Karma.',
  hereIsHowItWorks: 'Como funciona',
  learnMoreAboutGives: 'Saiba mais sobre o Karma Gives',
  alwaysKnowWhenPricesDrop: 'Fique por dentro das promoções',
  getNotifiedWhenBackInStock: 'Seja avisado quando voltar ao estoque',
  findsYouBestCoupons: 'Encontre os melhores cupons',
  getKarmaCashWhenYouShop: 'Use Karma Cash ao fazer compras',
  easilySaveAllThings: 'Economize em seus itens favoritos {{value, number}} em milhares de lojas',
  karmaProvidesGreatTechnology:
    'Karma oferece tecnologia de ponta e flexibilidade para comprar com inteligência, evitar compras impulsivas desnecessárias e economizar seu tempo e dinheiro.',

  KeepYourWants: 'Mantenha seus "desejos" em um só lugar',
  KeepYourWants2: 'Salve coisas das suas marcas favoritas no Karma e nunca perca uma oferta.',
  accountActivityAndImportantInformation: 'Atividade da conta e informações importantes',
  addKarmaToYourDesktop: 'Adicione Karma ao desktop para poder comprar<br/>durante o horário de trabalho.',
  addNumberItemsToList: 'Adicionar {{value}} itens à lista',
  addOneItemToList: 'Adicionar 1 item à lista',
  addStuffYouFindToThisListToKeepItOrganized:
    'Adicione a esta lista coisas que você encontrar para mantê-la organizada.',
  alrightBut: 'Tudo bem, mas você vai passar um FOMO absurdo!',
  areYouSureYouWantToDisconnectFromPaypal: 'Quer mesmo se desconectar do Paypal?',
  balance: 'Saldo',
  buyWhateverYouWant: 'Compre o que quiser e deixe a mulher do banco feliz.',
  cashIsBack: '<1>Dinheiro</1> de volta',
  cashIsBackColoredRight: 'Dinheiro <1>de volta</1>',
  cashOutRewards: 'Saque recompensas',
  checkItOffAsYouBuy: 'Risque após comprar',
  congratulationsOnCashout: 'Parabéns! Agora você pode sacar suas recompensas.',
  connectToPayPalToCashOut: 'Conecte uma conta do PayPal para sacar suas recompensas',
  connectYourPaypalAccountToCashOutRewards: 'Conecte sua conta do PayPal para sacar suas recompensas.',
  downloadOurApp: 'Baixe nosso app e use o <1>Karma onde estiver</1>',
  dropSomeShoppingInspo: 'Inspire a sua família a comprar.',
  earnKarmaCashShort: 'Até {{percent, number}}% de K Cash',
  earned: 'Ganhos',
  easyToSaveStuff:
    'Salve coisas com apenas um clique de 50K+ lojas para poder comprá-las na hora certa, pelo preço certo.',
  enterYourPassword: 'Digite sua senha',
  firstAddToList: 'Primeiro, adicione à lista',
  followOnLinkedin: 'Siga-nos no LinkedIn',
  freshOutCoupons: 'Os cupons acabaram',
  getBuzzedWhenYourWishes: "Seja avisado 🐝 quando seus 'desejos' ficarem mais baratos ou voltarem ao estoque.",
  getExtension: 'Psiu! Baixe nossa extensão de navegador',
  getExtension2: 'Salve o que você ama no Karma, de qualquer lugar, com apenas um clique.',
  getExtensionButton: 'Baixar extensão',
  getIt: 'Pegar',
  getTheApp: 'Baixe o app',
  gotIt: 'Entendi',
  gotStuck: 'Ficou preso? Clique <1>aqui</1> para prosseguir.',
  happyShoppers: '👉 {{count, number}}M+ de consumidores satisfeitos',
  howElseCanWeImproveKarma: 'De que outra forma podemos melhorar o Karma?',
  iNeedThisInMyLife: 'Preciso disso na minha vida',
  info: 'Informações',
  itemUpdatesUnsubscribe: 'Atualizações de itens (quedas de preço, mais estoque e mais)',
  itsAGhostTown: 'Que cidade fantasma!',
  joinTheTeam: 'Junte-se à equipe',
  karmaGetsYouTheLowestPossiblePrice: 'O Karma informa o menor preço possível 👏 todas 👏 as vezes.',
  karmaIsAnApp: 'Karma é um app e extensão que te ajuda a economizar tempo e dinheiro ao fazer compras online.',
  karmaYourFavoriteBrands: 'Dê um <1>Karma</1> nas suas marcas favoritas',
  karmaYourFavoriteBrandsMobile: 'Dê um <1>Karma</1> nas suas marcas favoritas',
  knowHowToShopBetter: 'Saiba como <1>comprar melhor</1> do que o resto do mundo',
  letsPlay: 'Vamos jogar',
  lolWereAVerbNow: '(rs, agora somos uma ação)',
  lookAtYouLittleScroller: 'Olha só você, seu curioso. Parabéns por chegar até aqui.',
  lookAtYouLittleScrollerMobile: 'Olha só você, seu curioso.',
  lookingForInfluencer: 'Procurando parcerias de publicidade?',
  markItemsYouveMadeYourOwnItFulfillsSoGood: 'Marque os itens que você adquiriu. É muito gratificante!',
  markNumberItemsAsFullfilled: 'Você está prestes a marcar {{value}} itens como realizados',
  meetYouAtIn: 'Te encontro na {{retailer}} em 3 segundos...',
  missOut: 'fique de fora',
  missingYouAlready: 'Já estou com saudade!',
  money: 'dinheiro',
  never: 'Nunca',
  nevermind: 'Não',
  noFomoHere: 'Aqui não tem FOMO. Te avisaremos quando seus ‘desejos‘ ficarem mais baratos ou voltarem ao estoque.',
  noNotificationsYet: 'Você está em dia',
  noNotificationsYet2: 'Assim que o preço cair ou o estoque for atualizado, você verá aqui.',
  nowLetsTalPrivacy: 'Agora vamos falar de <1>privacidade<1/>',
  nowLetsTalkAboutPrivacy: 'Agora vamos falar de privacidade 🤫',
  onlineShoppingButBetter: 'Compras online, mas <1>melhor</1>',
  orCopyTheLinkBelowAndSend: 'Ou copie o link abaixo e envie para eles à moda antiga.',
  other: 'Outro',
  otherSupportedStores: 'Outras lojas aceitas',
  passwordValidationNote:
    'Deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, uma letra minúscula e um número.',
  payLess: 'Pague menos',
  playFavesWithYourItems: 'Use os Favoritos com seus itens',
  preparingYourKarmaCash: 'Preparando seu Karma Cash.',
  savingMoneyIsHotLetsGetOnThat: 'Economizar é atraente. Vamos nessa ✌️',
  search: 'Buscar',
  seeHowToSave: 'Veja como salvar',
  shopSmarterWithExt: 'Obtenha nossa extensão, compre quando estiver 🔥 ',
  skipForNow: 'Pular por enquanto',
  soFarWeSavedYou: 'Até agora, te economizamos',
  sorryForInconvenience: 'Desculpe-nos pelo inconveniente. Prometemos que valerá a pena.',
  starReviews: '👉 {{count, number}}K+ avaliações 4,7 estrelas',
  starTheThingsYouNeedInYourLifeForQuickAccess:
    'Coloque uma estrela nas coisas que você precisa e acesse-as mais rápido.',
  stillWannaUnsubscribe: 'Ainda quer <1>cancelar o recebimento de todos os e-mails do Karma</1>?',
  storePromotionsAndOtherMarketingEmails: 'Promoções de lojas e outros e-mails de marketing',
  storesWorldwide: '👉 {{count, number}} K+ lojas em todo o mundo',
  stuffWorthSavingGoesHere: 'Aqui ficam as coisas que valem a pena salvar 👇',
  sureYouWantToDeleteTheseNumberItems: 'Quer mesmo excluir esses {{value}} itens?',
  sureYouWantToDeleteThisList: 'Quer mesmo excluir essa lista?',
  takeControlOfYourInbox: 'Assuma o controle da sua caixa de entrada',
  theMotherOfAllWishLists: 'A mãe de todas as <1>listas de desejos<1/>',
  thisFieldIsRequired: 'Este campo é obrigatório',
  toCashOutRewards: 'Para sacar suas recompensas, você deve atingir um mínimo de ${{minimum, number}}.',
  toCashOutYouMustReach: 'Para sacar suas recompensas, você deve atingir um mínimo de ${{currency}}{{value, number}}.',
  tryAgain: 'Tente novamente',
  trySearchingForSomethingElse: 'Tente procurar outra coisa.',
  updatePreferences: 'Atualizar preferências',
  wantIt: 'Eu quero',
  weAreBusySelfImproving: 'Estamos ocupados nos aperfeiçoando',
  weAreVerySeriousAboutPrivacy:
    'Levamos sua privacidade muito a sério e nunca venderemos seus dados a terceiros. Nunca.',
  weDontSellYourData: 'Não vendemos os seus dados',
  weGotStuckOnOurWay: 'Ficamos presos a caminho da {{retailer}}!',
  weHitYouUp: 'Entraremos em contato quando encontrarmos um código promocional para essa loja.',
  weHustleForCoupons: 'Vamos atrás de cupons',
  weUseBankLevelSecurity: 'Usamos segurança de nível bancário',
  wellApplyCodesThatSaveYouMoney: 'Aplicamos códigos que lhe poupam $$$.',
  wellTrackAndScanForCoupons:
    'Rastreamos e procuramos cupons para que você não precise fazer isso. <br/>(Tá, a gente não, a nossa IA)',
  wellTrackAndScanForCouponsMobile: 'Rastreamos e procuramos cupons para que você não precise fazer isso.',
  wereAVerbNowLol: '(rs, agora somos uma ação)',
  wereGoingShopping: 'Vamos às compras',
  wereHereToMakeItYours: 'Estamos aqui para <1>#make_it_yours</1>',
  whyUnsubscribeFromAllOurMoneySavingEmails:
    'Por que cancelar o recebimento de todos os nossos e-mails que poupam dinheiro quando você pode desativar só os que não são relevantes?',
  withKarmaYouCanBuy: 'Com o Karma, você pode comprar<br />o que quiser e manter<br />a mulher do banco feliz.',
  withKarmaYourCash: 'Com o Karma, seu dinheiro volta para você como uma espécie de dinheiro mágico ou vudu.',
  yesDisconnect: 'Sim, desconectar',
  yesIDoThat: 'Sim, eu faço isso',
  youHaveToReconnectToWithdraw: 'Você terá que se reconectar para sacar seu Karma Cash.',
  youNeedToSaveItemsBeforeYouCanShareYourList: 'Você precisa salvar itens antes de poder compartilhar sua lista.',
  youReATen: 'Você é nota 10 e paga com Karma? Compre online e consiga o menor preço possível 👏 todas 👏 as vezes.',
  youReAboutToMuteNumberItems: 'Você está prestes a silenciar notificações para {{value}} itens',
  yourCashWillComeBackToYou: 'Seu dinheiro voltará para você como uma espécie de dinheiro mágico ou vudu. ',
  yourInformationIsSecured:
    'Suas informações são protegidas usando a criptografia de 256 bits mais moderna para proteção completa.',
  yourSearchCameUpEmpty: 'Sua pesquisa não gerou resultados',
  total: 'Total',
  keepAnEyeOutForTheStatusUpdatesWeWillSendToEmail:
    'Fique de olho nas atualizações de status que enviaremos para {{email}}.',
  psaDidYouKnow: 'PSA: Sabia que você pode',
  addWishItemsFromAnyBrand:
    "Adicionar itens 'desejados' de qualquer marca e aguardar até dizermos que é hora de comprar.",
  capturePurchaseReadyShoppers: 'Atrair consumidores prontos para comprar, mostrando seus cupons mais interessantes.',
  gainFullFunnelInsights: 'Obter insights de full-funnel sobre a intenção do consumidor com cada item salvo.',
  boostCartEngagement: 'Aumentar o envolvimento com o carrinho com as nossas atualizações de itens em tempo real.',
  promoteLongTermGrowth:
    'Promover o crescimento no longo prazo através de campanhas personalizadas dentro do ecossistema Karma.',
  reachNewAudiences: 'Alcançar novos públicos através de diversas campanhas adaptadas à sua marca',
  influencerCollaborations: 'Colaborações com influenciadores',
  teamUpWithTopInfluences: 'Junte-se aos principais influenciadores que mais combinam com a identidade da sua marca.',
  socialPosts: 'Publicações sociais',
  giveANewFollowing: 'Consiga novos seguidores com campanhas personalizadas e ricas em conteúdo.',
  brandFocusedNewsletters: 'Newsletters focadas na marca',
  letYourOffersShine: 'Deixe suas ofertas brilharem em e-mails selecionados e muito aguardados',
  driveTrafficWhenYouNeedIt:
    'Conduza o tráfego para onde você precisa que ele vá com nossas inserções em página inicial para compras',
  dashboardBanners: 'Banners do painel',
  flauntYourLatestOfferings: 'Mostre suas ofertas mais recentes com recursos visuais atraentes.',
  dailyFeatured: 'Destaque do dia',
  highlightDeals: 'Destaque as ofertas onde é mais importante.',
  dedicatedPopUps: 'Pop-ups dedicadas',
  activelyServeYourPromos: 'Mostre ativamente suas promoções para ninguém as perder.',
  createAnotherTouchpointForYourBrand: 'Crie outro ponto de contato para sua marca',
  increaseEngagement:
    'Aumente as taxas de engajamento e conversão por meio de notificações push segmentadas em dispositivos móveis e na web.',
  karmaIsOnlineShoppingButBetter: 'Karma são compras online, mas melhor.',
  transactionsNumber: '👉 {{count, number}}M+ de transações',
  partnershipsReview4: 'Isso é fantástico para criar sua lista de desejos e economizar!',
  partnershipsReviewAuthor4: 'Emily Smith',
  partnershipsReview5:
    'É literalmente a melhor extensão que já baixei. É o melhor para economizar e ganhar dinheiro ao mesmo tempo!',
  partnershipsReviewAuthor5: 'Selena Roblox',
  noNeedToSlideIntoOurDMs:
    'Não precisa nos mandar direct (mas pode, se quiser 😏). Nossa equipe está aqui para o que você precisar.',
  supportIssues: 'Problemas com suporte',
  adsAndPartnerships: 'Anúncios e Parcerias',
  jobOpportunities: 'Vagas de emprego',
  publicRelations: 'Relações públicas',
  letsChat: 'Vamos conversar',
  earnings: 'Ganhos',
  allTime: 'Totais',
  ordersMayTakeHoursToAppear: 'Os pedidos podem demorar até {{value, number}} horas para aparecer.',
  youHaventReceivedAnyPayoutsYet: 'Você ainda não recebeu nenhum pagamento ',
  alerts: 'Alertas',
  featuresReview1:
    'Adicionei um par de tênis à minha lista de desejos e literalmente no dia seguinte recebi um aviso de que estava com 50% de desconto. Como é que eu comprava as coisas sem vocês?',
  featuresReviewAuthor1: 'Sandra C. Martinez, avaliadora da App Store',
  featuresReview2:
    'Este é sem dúvidas o melhor app de compras inteligente, e eu conheço vários outros. Na verdade, sinto que estou enganando o sistema um pouco porque estou economizando de verdade! Eu sempre costumava pagar o valor cheio e me sentir como uma otária',
  featuresReviewAuthor2: 'Gertie D. Ackerman, avaliadora da App Store',
  featuresReview3:
    'Estava comprando um notebook novo que já estava com 25% de desconto. Quando apertei ‘pagar com Karma’, notei que o total era ainda menor. Isso acontece sempre que pago com o aplicativo',
  featuresReviewAuthor3: 'Irene J. Cox, avaliadora da App Store',
  downloadNow: 'Baixe agora',
  wannaBeDeskBuddies: 'Quer ser colega de mesa?',
  seeOpenPositions: 'Ver vagas',
  weLiveAndSwearBy: 'Vivemos e juramos por...',
  goodKarma: 'Bom Karma',
  weBelieveThatGoodVibes: 'Acreditamos que ~boas vibrações~ são um estilo de vida e nos apegamos a isso.',
  beingTrue: 'Ser verdadeiro',
  theresNoUSvsU:
    'Não existe isso de NÓS contra VOCÊ. Nossas mesas são sempre redondas e reservamos um lugar para você.',
  passion: 'Paixão',
  weHaveThatFireYouKnow:
    'A gente tem aquele fogo, sabe? Funcionamos à base de motivação, persistência e amor pelo que fazemos.',
  entrepreneurship: 'Empreendedorismo',
  weWantItWeGetIt:
    'Quando queremos, conseguimos. Não temos tempo a perder com Zooms sem fim. Vamos atrás do que queremos.',
  mutuality: 'Mutualidade',
  wereMadeUpOfAwesomePeople:
    'Somos todos pessoas incríveis que entendem que trabalho em equipe não é suficiente. Realmente nos importamos uns com os outros.',
  buildCustomerLoyalty: 'Fidelize clientes com cashback instantâneo',
  itemSaving: 'Salvamento de itens',
  itemUpdates: 'Atualizações do item',
  backInStockAlerts: 'Voltou ao estoque',
  lowInStockAlerts: 'Estoque baixo',
  storePromotions: 'Promoções da loja',
  exclusive: 'exclusivo',
  couponCopied: 'Cupom copiado',
  completedDesc:
    'A loja confirmou seu pedido. Todo Karma Cash obtido pode ser retirado {{days, number}} dias após a data da compra e somente depois de atingir o mínimo de {{currency}}{{amount, number}}.',
  notQualifiedDesc: 'Sujeito a restrições adicionais aos itens encomendados.',
  pendingDesc:
    'A loja recebeu seu pedido. Poderá aparecer como pendente por até {{days, number}} dias, dependendo da política de devolução da loja.',
  cancelledDesc: 'A loja nos informou que seu pedido foi cancelado ou reembolsado integralmente.',
  downloadNowItsFree: 'Baixe agora, é grátis',
  weLiveToMakeOurShoppersHappy: 'Vivemos para fazer nossos consumidores felizes',
  moneySavedForYou: 'Dinheiro economizado para você',
  happyShoppers2: 'Consumidores felizes',
  starReviews2: 'Avaliações {{value, number}} estrelas',
  storesWorldwide2: 'Lojas em todo o mundo',
  whyYouWill: 'Por que você vai',
  it: 'ele',
  multiBrandWishLists:
    'Listas de desejos com várias marcas? Arrepios pra valer. Identifique algo que você adora de qualquer marca, adicione à sua lista do Karma e aguarde até avisarmos que é hora de comprar.',
  addKarmaToYourMobile: 'Baixe<br />nosso app<br />e use o<br />Karma onde estiver',
  appStoreReview1:
    'Adicionei uma paleta de sombras à minha lista e no dia seguinte recebi uma notificação de que estava com 50% de desconto. Obrigada!!',
  appStoreReview2: 'O fato de o preço mais baixo estar bem ao meu alcance é um perigo. Mas também amo isso',
  appStoreReview3:
    'Acabei de conseguir o menor preço em fones de ouvido novos enquanto esperava o ônibus. É fácil demais',
  appStoreReviewBy: 'Avaliação em loja de apps por: {{name}}',
  yourInfoSafeWithUs: 'Suas informações estão seguras com a gente',
  whyGiveOutYourCCDeets: 'Por que dar seus dados de cartão de crédito quando pode mantê-los em um só lugar?',
  partyForOne: 'Reserva pra 1',
  wellNeverSellYourDataToAnyThirdParties:
    'Nunca venderemos seus dados a terceiros, ponto final. Com segurança de nível bancário, seus dados estão protegidos contra olhares indiscretos.',
  buyWithoutATrace: 'Compre sem deixar rastros',
  weWontLetAnyoneTrackYourShoppingHabits:
    'Não deixaremos ninguém rastrear seus hábitos de compras. Aqui ninguém te julga.',
  spotSomethingYouLoveFromAnyBrand:
    'Identifique algo que você adora de qualquer marca, adicione à sua lista do Karma e aguarde até avisarmos que é hora de comprar.',
  needIt: 'Preciso disso',
  appStoreReviewer: 'Avaliador da App Store',
  appStoreReview4:
    'Confio muito neste app para manter o controle de todos os meus itens desejados em um só lugar para conseguir o melhor preço possível.',
  appStoreReview5: 'Este app me ajudou a economizar muito dinheiro.',
  wishlist: 'Lista de desejos',
  getTheLowestPriceAtAllYourFavoriteBrands: 'Consiga os preços mais baixos em todas as suas marcas favoritas.',
  getTheAppForFree: 'Baixe o aplicativo grátis',
  addKarmaSpaced: 'Adicione Karma<br />ao desktop<br />para poder comprar<br />durante o horário de trabalho.',
  spotSomethingYouLoveFromAnywhere:
    'Identifique algo que você adora de qualquer lugar, adicione à sua lista do Karma e aguarde até avisarmos que é hora de comprar.',
  getCouponsCashbackWithPeriod: 'Receba cupons, cashback e alertas de descontos.<br />Pague sempre o preço mais baixo.',
  getCouponsCashbackWithAnd: 'Receba cupons, cashback, alertas de descontos<br />e pague sempre o preço mais baixo.',
  clickTheLoginLinkFromYourPhone: 'Clique no link de login do seu telefone para acessar o app Karma.',
  getItOn: 'Obtenha na',
  downloadOnThe: 'Baixe na',
  likeShoppingFromYourComputer: 'Curte fazer compras no seu computador?',
  getTheKarmaExtensionToShopEvenBetter: 'Baixe a extensão do Karma e compre ainda melhor.',
  headerMobileLogin: 'Karma | Instalar',
  noFomoHereMobile:
    'Aqui ninguém passa vontade. Te avisaremos quando seus ‘desejados‘ ficarem mais baratos ou voltarem ao estoque.',
  withKarmaYourCashMobile: 'Com o Karma, seu dinheiro volta para você como uma espécie de dinheiro mágico ou vudu.',
  spotSomethigYouLoveMobile:
    'Identifique algo que você adora de qualquer lugar, adicione à sua lista do Karma e aguarde até avisarmos que é hora de comprar.',
  wellTrackAndScanForCouponsMobile2:
    'Rastreamos e procuramos cupons para que você não precise fazer isso. (Tá, a gente não, a nossa IA)',
  weHustle: 'A gente sai atrás',
  forCoupons: 'de <1>cupons</1>',
  theMotherOfAll: 'A mãe de todas as',
  wishlists2: 'listas de desejos',

  // NEW PHRASES
  demoSignupSlideTitle1: 'Leave the <1>coupon hunt</1> up to us',
  demoSignupSlideTitle3: "Don't miss a drop",
  demoSignupSlideTitle4: 'Cash is cold, <1>but saving is hot</1>',
  demoSignupSlide1: "We'll apply them at checkout",
  demoSignupSlide3: 'We’ll hit you up when it’s the best time to buy the stuff you saved',
  demoSignupSlide4: 'Get Karma Cash when you buy the stuff you love.',
  letsStart: "Let's start",
  moneySavedForUsers: 'Money saved for users',
  whyYouLoveMerchantsTitleDesktop3: '<1>One-swipe<1/> checkout is yours',
  whatItsLikeWorkingAtKarma2: 'What’s it like working at Karma?',
  makeItYours: 'Make it yours',
  continueWith: 'Continue with {{provider}}',
  onItsWay: 'On its way...',
  weVeEmailedInstructionsForResettingYourPassword:
    'We’ve emailed instructions for resetting your password to {{email}}',
  wrongEmailTryAgain: 'Wrong email? Try again',
  goToEmail: 'Go to email',
  heyYouLetsJumpBackIntoSaving: 'Hey you! Let’s jump back into saving',
  firstConfirmItsYou: 'First, confirm it’s you',
  letsDoThis: 'Let’s do this',
  signUpAndGet: 'Sign up and get',
  moneyOnUs: '{{currency}}{{value, number}} on us',
  usersHeroTitle: 'Your\nbank account’s gonna love this.',
  tellMeMore: 'Tell me more',
  createNewPassword: 'Create new password',
  joinKarma: 'Join Karma',
  whyYouLoveMerchantsSubtitle3: 'Pay with Karma works with all retail platforms, with zero\nintegration and no fees.',
  saveItemsFromStores: 'Save items from your favorite stores with a single click',
  easilySaveAndKeep:
    'Easily save and keep track of everything you want to buy. The best\npart? You’ll get notified the second any of your saved items\ngo on sale, or come back in stock.',
  addTheFreeKarma:
    "Add the free Karma button to your browser so you can\neasily keep track of everything you want to buy. The best\npart? You'll get notified the second any of your saved items\ngo on sale, drop in price, or come back in stock.",
  getButtonAndGetEverything: 'Get the free Karma button and\nget everything you want on sale',
  getKarmaItIsFree: "Get Karma - It's Free!",
  onYourPhone: 'On your phone? Save items on the go with our free app',
  hereHowTheButtonWorks: "Here's how the Karma\nbutton works",
  dontSellMyPersonalInformation: 'Don’t sell my personal information',
  postCode: 'Postcode',
  ifThisWontHelp: "If this won't help, ",
  contactSupport: 'contact our support center.',
  wellKeepAnEyeOnIt: 'We’ll keep an eye on it & will let u know ASAP if the price drops',
  seeYourSavedItems: 'See your saved items',
  logInAndSaveItemToKarma: 'Login & Save item to Karma',
  blogPromotions: 'Blog promotions',
  expiringIn: 'Expiring in',
  goToStore: 'Go to store',
  visitStorePage: 'Visit store page',
  favStoresUpdated: 'Your favorite stores were successfully updated.',
  availableCoupons_plural: '{{count}} available coupons',
  availableCoupons: '{{count}} available coupon',
  findAndComparedTheBestDeals: 'Finds and compares the best deals',
  karmaHelpsYouShopSmarter: 'Karma helps you shop smarter by instantly comparing products from different retailers.',
  youSuccessfullyInstalledTheExtension: 'You successfully installed the extension',
  scanForCoupons: 'Scan for coupons',
  weWillLetYouKnowIfCouponsAreAvailableAtStoresCheckout:
    'We will let you know if coupons are available at the store’s checkout',
  compareProducts: 'Compare products',
  easilyComparePricesFromMultipleStores:
    'Easily compare prices from multiple stores and make sure you’re getting the best price.',
  knowWhenToBuy: 'Know when to buy',
  saveTheProductsYouLove: 'Save the products you love, and we will let you know when the price gets hot',
  youAreAllSet: 'You’re all set!',
  letsGoShopping2: 'let’s go <1>Shopping</1>',
  withKarmaItsAllGoodVibes:
    'With Karma, it’s all good vibes! We’re here to  jazz up your shopping experience, and ensure you’re getting the most bang for your buck.',
  illDoItTakeMeTo: 'I’ll do it later, take me to ',
  shopAtStore: 'Shop at {{storeName}}',
  needMoreInfo: 'Need more info? Visit <2>Help Center</2> or <6>contact us</6>',
  howSecureIsKarma: 'How secure is Karma?',
  whereCanIFindCoupons: 'Where can I find coupons?',
  howDoISaveItemsToKarma: 'How do I save items to Karma?',
  toSaveAnItemOnKarma:
    'To save an item on Karma while browsing a supported retailer site, go to the specific item page and click the Karma button on the right. Then, choose the item preferences and optionally select a list to add the item to.',
  weUnderstandThatInstallingAnExtension:
    'We understand that installing an extension involves a great deal of trust on your part; we take this trust very seriously, and make it our highest priority to ensure the confidentiality of the personal information you provide to us.',
  theKarmaExtensionTracks:
    'The Karma extension tracks down the best deals and applies coupons and offers automatically at store checkout. ',
  expiring: 'Expiring',
  today: 'Today',
  tomorrow: 'Tomorrow',
  days: '{{count, number}} DAY',
  weeks: '{{count, number}} WEEK',
  months: '{{count, number}} MONTH',
  daysPlural: '{{count, number}} DAYS',
  weeksPlural: '{{count, number}} WEEKS',
  monthsPlural: '{{count, number}} MONTHS',
  tellUsWhyYouUninstalledTheExtension: 'Tell us why you uninstalled the extension',
  tooManyPopUps: 'Too many pop-ups',
  foundABetterTool: 'Found a better tool',
  couponsDontWork: 'Coupons don’t work',
  dataPrivacyConcerns: 'Data privacy concerns',
  causedProblemsWithMyBrowser: 'Caused problems with my browser',
  tellUsMoreOptional: 'Tell us more (optional):',
  shareYourThoughtsWithUs: 'Share your thoughts with us',
  myFavoriteStoresAreNotSupported: 'My favorite stores are not supported',
  notReadyToLetGoYet: 'Not ready to let go yet? Reinstall Karma with just one click',
  chatWithUsAndGet: 'Chat with us & get<br />${{count}} Amazon Gift Card',
  scheduleAMeetingAndEarnGiftCard:
    'Schedule a {{minutes}}-minute interview and earn a<br />${{count}} Amazon gift card.',
  scheduleAnInterview: 'Schedule an interview',
  compareProductsBeforeYouBuy: 'Compare products before you buy',
  downloadWithKarmaAndSaveMajorCash: 'Download Karma<br/>and save major $$$',
  welcomeBack: 'Welcome back!',
  signInAndGoShopping: 'Sign in and let’s  <1>go shopping</1>',
  dontHaveAnAccount: 'Don’t have an account? <2>Sign up</2>',
  letsBookAVacation: 'Let’s book a <1>vacation</1>',
  getReadyForUnforgettableJourneys:
    'Get ready for unforgettable journeys while saving money along the way! Our powerful algorithms scour the web to maximize the value of your travel budget. We’re thrilled to have you on board!',
  visit: 'Visit',
  getCashBack: 'Get cash back',
  activateKarmaCashAndEarn: 'Activate Karma cash back & earn $$$ every time you shop',
  discoverTravelDeals: 'Discover Travel Deals',
  exclusiveDealsForTravelers: 'Exclusive deals for travelers straight into your inbox',
  youSearchedFor: 'You searched for',
  yourSavedItems: 'Your saved items',
  moreForYou: 'More for you',
  headerGlobalSearch: 'Karma | {{query}}',
  noResultsFoundFor: 'No results found for',
  pleaseReviewYourSpellingOrTryDifferentKeyword: 'Please review your spelling or try different keyword',
  heyThere: 'Hey, {{name}}',
  myAccount: 'My account',
  accountSettings: 'Account settings',
  trendingNow: 'Trending now',
  helpUsImproveKarma: 'Help us improve Karma',
  yourFavoriteStores: 'Your favorite stores',
  chooseYourFavoriteStores: 'Choose your favorite stores',
  viewStoreInfo: 'View store info',
  addStore: 'Add store',
  findMoreStores: 'Find more stores',
  whatAreYouShoppingFor: 'What are you shopping for?',
  searchForAnyItemBrandOrStore: 'Search for any item, brand or store',
  savedFromStore: 'Saved from store',
  manageFavoriteStores: 'Manage favorite stores',
  hasNoActiveCoupons: '{{retailer}} has no active coupons',
  checkOutOtherStores: 'Check out other stores for more deals',
  youCanStillEarn: 'You can still earn {{number}}% cashback with Karma!',
  retailerCoupons: '{{retailer}} coupons',
  nothingSavedYet: 'Nothing saved yet?',
  useOurBrowserExtension:
    'Use our browser extension on your {{retailerName}} visit to quickly add items to your favorites. Your shopping journey starts here!',
  letsMakeItPersonal: 'Let’s make it personal',
  selectAndTrack: 'Select your favorite stores and track coupons and deals',
  chooseYourFaves: 'Choose your faves',
  code: 'Code',
  itemFound: '{{count, number}} item found',
  itemFound_plural: '{{count, number}} items found',
  storeFound: '{{count, number}} store found',
  storeFound_plural: '{{count, number}} stores found',
  itemsTitle: 'Items',
  storeInfo: 'Store info',
  preOwned: 'Pre-owned',
  refurbished: 'Refurbished',
  favorited: 'Favorited',
  allPromotionsOrOffers:
    "All promotions or offers by any of our partners are subject to the applicable partner's terms and conditions, which may be changed by the partner at its discretion. We may receive a commission for purchases made through our links or using our coupons. Eligibility for a welcome bonus is subject to section 5 of Karma’s Terms & Conditions.",
  shippingInfo: 'Shipping info.',
  freeShipping: 'Free shipping',
  overMinAmount: 'over {{currency}}{{value, number(minimumFractionDigits: 2)}}',
  delivers: 'Delivers',
  deliveryDays: '{{value, number}} days',
  freeReturns: 'Free returns',
  upToDays: 'up to {{value, number}} days',
  deliveryInfo: 'Delivery info.',
  returnsInfo: 'Returns info.',
  notAvailable: 'Not available',
  minOrderMayApply: 'Min. order may apply',
  hottestDeals: 'Hottest deals',
  discoverCouponsAndExclusiveOffers: 'Discover coupons and exclusive offers',
  moreFromStore: 'More from {{store}}',
  seeWhatsTrendingNow: 'See what’s trending now',
  automaticallyApplyCoupons:
    'Automatically apply coupons at checkout, save what you love and earn cashback — all with the Karma extension.',
  deleteMyAccount: 'Delete my account',
  areYouSureDeleteAccount: 'Are you sure you want to delete your account?',
  youHaveKarmaCash: 'You have {{currency}}{{value, number}} Karma Cash in your wallet.',
  deletingYourAccountWill:
    'Deleting your account will permanently remove all of your information, your saved items and your earnings from our database, and this action cannot be undone.',
  cancel: 'Cancel',
  accountDeletedSuccessfully: 'Account deleted successfully',
  shopOnTheGo: 'Shop on-the-go with Karma app',
  moveFromDevice: 'With Karma, effortlessly move from one device to another as you shop.',
  fewMomentsWeAreStillWorkingOnSavingItemsToThisList:
    'Few moments, we’re still working on saving items to this list... ',
  notificationDate: '{{date, shortFromNowDate}} ago',
  onboardingIOSTitle: 'Enable Karma on Safari \n to access our coupon scanner',
  enableKarmaOnSafari: 'Enable Karma on Safari to access our coupon scanner',
  alwaysAllowOnSites: 'Always allow on all sites to get access to coupons',
  iosOnboardingFlowSubtitle: '{{step, number}}/{{numberOfSteps, number}} {{text}}',
  extension: 'extension',
  howToEnable: 'How to enable',
  howToGetCoupons: 'How to get coupons',
  sweOnboardingStep1_1: 'Tap the <1>A</1><2>A</2> in the URL bar',
  sweOnboardingStepAlt1_1: 'Tap the aA in the URL bar',
  sweOnboardingStep1_2: 'Select “Manage Extensions”',
  sweOnboardingStep1_3: 'Toggle on',
  sweOnboardingStep2_1: 'Tap the <1>A</1><2>A</2> in the URL bar again',
  sweOnboardingStep2_2: 'Tap the',
  sweOnboardingStep2_3: 'Select “Always allow”',
  sweOnboardingStep2_4: 'Select “Always allow on every website”',

  sweOnboardingStep2_5_1: 'Go back to the',
  sweOnboardingStep2_5_2: 'Karma app',
  shopSmarter: 'Shop smarter with\nthe power of AI',
  joinOurUsers: 'Join our {{number, number}} million users\nwho are shopping\nsmarter from all their\ndevices',
  browseAndBuy:
    'Browse and buy from our desktop extension or\nuse the Karma app to ensure you’re always\ngetting the most out of your purchase.',
  joinCard1: '<0>{{value}}M+</0><1>shoppers</1>',
  joinCard2: '<0>15K</0><1>5 star reviews</1>',
  joinCard3: '<0>$5 cash</0><1>at sign-up</1>',
  joinCard4: '<0>2.3 million</0><1>mobile app users</1>',
  joinCard5: '<0>$7.50</0><1>avg order savings</1>',
  joinCard6: '<0>50 million</0><1>items tracked</1>',
  whatMakesKarmaGood: 'What makes Karma\nso good?',
  karmaGoodTitle1: 'Karma let’s you shop\nsmarter from anywhere',
  karmaGoodDescription1:
    'Karma’s browser extension meets you\nwherever you shop. Shop directly in the Karma\napp or share the items you want to Karma.',
  karmaGoodTitle2: 'Helps you plan your\nnext purchases',
  karmaGoodDescription2:
    'Not ready to buy just yet? Save it for later in the\nKarma app or share it to Karma and shop more\nmindfully. You can keep an eye on all your\nsaved items in one place.',
  karmaGoodTitle3: 'Finds and compares the\nbest deals',
  karmaGoodDescription3: 'Karma helps you shop smarter by instantly\ncomparing products from different retailers.',
  karmaGoodTitle4: 'So you can buy at the\nright time',
  karmaGoodDescription4:
    "Karma uses clever technology to track if the\nprice drops, or when it's back in stock, so you\ncan buy at the right time.",
  onlyOnMobile: 'Only on the mobile app',
  makeYourInspo: 'Make your inspo\na reality',
  easilyBringYourFavorite:
    'Easily bring your favorite social media looks\ninto your life, just share the posts to Karma and\nour AI will scan the image and find the items\nthat match.',
  empowerTouToMake: 'Empower you to\nmake the right\nchoices at the right\ntime when you shop\nonline.',
  dontTakeOurWord: 'Don’t take our word for it',
  totalSavings: 'Total savings',
  joinOurUsersDesktop: 'Join our {{number, number}} million users who are\nshopping smarter from all their devices',
  andAutomaticallyFindsYouCouponsAtCheckout: 'And automatically finds you coupons at checkout',
  clickAlwaysAllowOnEveryWebsite: 'Click <2>“Always Allow on Every Website“</2> so we can work on all your fave sites',
  weNeedYourPermissionToAutomaticallyShowYouTheBestDeals:
    'We need your permission to automatically show you the best deals on all your favorite shopping websites.',
  yourPrivacyAndSecurityIsImportantToUs: 'Your privacy and security are important to us - it’s in our',
  whileKarmaWillStillWork: 'While Karma will still work, it’ll require more effort on your part to find deals.',
  unfortunatelyWithoutTheAlwaysAllowPermission:
    'Unfortunately, without the “Always Allow on Every Website” permission you’ll have to manually click Karma whenever you shop',
  noThanksSignUpForAnAccount: 'No thanks, Sign up for an account',
  logInWithEmail: 'Log in with email',
  signUpWithEmail: 'Sign up with email',
  iAgreeToTermsOfUseAndPrivacyPolicy: 'I agree to <1>Terms of Use</1> and <3>Privacy Policy</3>',
  joinOurEnergeticTeam: 'Join Our Energetic Team',
  atKarmaWeReNotJustAboutWork:
    'At Karma, we’re not just about work; we’re about creating an environment where everyone’s input is valued and where each day brings a new opportunity for growth and impact.',
  beAPartOfTheJourney: 'Be a part of the journey',
  noPositionsAvailable: 'No positions available',
  apply: 'Apply',
  dontSeeAnythingThatFitsYourSkillSet:
    "Don't see anything that fits your skill set? Don't let that stop you from reaching out! If you think you can help us grow, send an email to",
  ifYouReReadyToMakeAnImpact:
    'If you’re ready to make an impact and thrive in a setting that values speed, fun, and collaboration, Karma might be your next great adventure.',
  exploreOurCareerOpportunities:
    'Explore our career opportunities and find out how you can contribute to our exciting journey ahead. Join us, and let’s achieve greatness together!',
  welcomeToKarma:
    'Welcome to Karma—where technology meets savvy shopping. Join us in our mission to revolutionize the way you shop, ensuring every purchase is just right for you.',
  empoweringSmartShopping: 'Empowering Smart Shopping:\nThe Karma Story',
  ourMissionPart1:
    'At Karma, we believe in empowering shoppers to make wise and informed purchasing decisions. Founded in 2015, our mission has always been to guide consumers toward smarter, more responsible shopping. As both a browser extension and mobile app, Karma harnesses the power of advanced AI to enhance your shopping experience. This technology predicts the optimal time to make a purchase, ensuring you get the best deal possible.',
  ourMissionPart2:
    'Over the years, Karma has continuously evolved, integrating cutting-edge technology to keep pace with the dynamic e-commerce landscape. We take pride in helping our users avoid buyer’s remorse and overspending by providing them with the tools they need to shop confidently!',
  hearFromOurUsers: 'Hear from our users',
  karmaPatents: 'Karma patents',
  systemAndMethodForPreauthorizing: 'System and method for preauthorizing database transaction commits',
  systemAndMethodForScraping: 'System and method for near real time web scraping',
  couponsHandled: 'Coupons? Handled',
  skipCouponHunt: 'Skip the coupon hunt. We’ll automatically apply the best discounts for you at checkout.',
  smartAlerts: 'Smart alerts for your wishlist',
  saveItemsGetAlerted: 'Save items and get alerted when prices drop or items are back in stock.',
  cashbackMadeSimple: 'Cashback made simple',
  earnMoneyBack: 'Earn money back on your purchases, turning shopping into savings.',
  startYourShoppingJourney: 'Start your shopping journey',
  searchForItemBrandStore: 'Search for an item, brand or store',
  quillDisclosure: 'HP, Epson, & Samsung excluded',
};
