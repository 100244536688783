import { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import Loader from '@karma/components/Loader';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import useWisePops from 'services/useWisePops';
import useSendBrazeEvent from 'hooks/useSendBrazeEvent';
import useExtensionDetection from 'hooks/useExtensionDetection';
import useIOSExtensionDetection from 'hooks/useIOSExtensionDetection';
import useSetCjeInCookies from 'hooks/useSetCjeInCookies';
import useInitHotJar from 'services/useInitHotJar';
import { RetailersDBContextProvider } from 'contexts/RetailersDBContext';
import * as userSelectors from 'store/user/selectors';
import AuthorizedRoute from 'components/organisms/AuthorizedRoute/AuthorizedRoute';
import UnauthorizedRoute from 'components/organisms/UnauthorizedRoute/UnauthorizedRoute';
import ServerRoutes from 'components/organisms/ServerRoutes/ServerRoutes';
import AppLayout from 'components/templates/AppLayout/AppLayout';
import KustomerButton from 'components/molecules/KustomerButton/KustomerButton';
import { DEMO_PATHS, PATHS } from 'consts/paths';
import useComponentWillMount from 'hooks/useComponentWillMount';
import useSetLanguageFromQueryParam from 'hooks/useSetLanguageFromQueryParam';
import useInitKustomer from 'hooks/useInitKustomer';
import Bonuses from 'services/Bonuses';
import { initBraze } from 'services/braze';
import { CSR_ROUTES, SSR_ROUTES } from 'routes';
import useHeaderBannersPorter from 'hooks/useHeaderBannersPorter';
import useGetUserData from 'hooks/useGetUserData';
import useUpdateRetailersDB from 'hooks/useUpdateRetailersDB';
import configureApiRequests from 'functions/configureApiRequests';
import useGoogleTagManager from 'hooks/useGoogleTagManager';

const App = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const userId = useSelector(userSelectors.getUserId);
  const ignoreKustomerButton = DEMO_PATHS.includes(pathname);

  useComponentWillMount(configureApiRequests);
  useExtensionDetection();
  useIOSExtensionDetection();
  useInitHotJar();
  useWisePops();
  useSetLanguageFromQueryParam();
  useSetCjeInCookies();
  useGetUserData();
  useUpdateRetailersDB();
  useInitKustomer();
  useSendBrazeEvent();
  useHeaderBannersPorter();
  useGoogleTagManager();

  Alooma.useClicksTracker();
  Bonuses.useInspector();

  useEffect(() => {
    const removeListener = history.listen(() => {
      window.scrollTo(0, 0);
      // we need this in case the user logged in and the previous attempt was unsuccessful due to the lack of a user id
      initBraze();
    });

    return () => {
      removeListener();
    };
  }, [history]);

  return (
    <AppLayout>
      <Suspense fallback={<Loader absolute />}>
        <Switch>
          {ServerRoutes}

          {/* DASHBOARD PAGES */}
          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.HOME}>
                <CSR_ROUTES.HomePage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={[PATHS.DASHBOARD, PATHS.HOME]}
          />

          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.NEW_SALES}>
                <CSR_ROUTES.NewSalesPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={PATHS.NEW_SALES}
          />

          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.HOW_IT_WORKS}>
                <CSR_ROUTES.HowItWorksPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={PATHS.HOW_IT_WORKS}
          />

          <AuthorizedRoute render={routeProps => <CSR_ROUTES.ProfilePage {...routeProps} />} path={PATHS.PROFILE} />

          {/* COUPONS */}
          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.COUPONS}>
                <CSR_ROUTES.CouponsPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            exact
            path={PATHS.COUPONS}
          />

          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.COUPONS__NEWEST}>
                <CSR_ROUTES.CouponsSeeAll {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={PATHS.COUPONS__NEWEST}
          />

          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.COUPONS__POPULAR}>
                <CSR_ROUTES.CouponsSeeAll {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={PATHS.COUPONS__POPULAR}
          />

          {/* ITEMS */}
          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.ITEMS}>
                <CSR_ROUTES.MySavedItemsPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            exact
            path={PATHS.ITEMS}
          />

          {/* STORES */}
          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.STORES}>
                <CSR_ROUTES.StoresPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={PATHS.STORES}
          />

          <AuthorizedRoute path={PATHS.STORES__FEATURED} component={CSR_ROUTES.RetailersSeeAllPage} />
          <AuthorizedRoute path={PATHS.STORES__TRENDING} component={CSR_ROUTES.RetailersSeeAllPage} />
          <AuthorizedRoute path={PATHS.STORES__WE_ARE_LOVING} component={CSR_ROUTES.RetailersSeeAllPage} />

          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.CASHBACK_PICKS}>
                <CSR_ROUTES.DashboardRetailersPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={PATHS.RETAILERS}
            exact
            relatedUnauthorizedLink={`${PATHS.KARMA_CASH}/$search`}
          />

          <AuthorizedRoute
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.DESIGNATED_STORE}>
                <CSR_ROUTES.DashboardRetailerPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            path={PATHS.RETAILER}
            relatedUnauthorizedLink={`${PATHS.KARMA_CASH}/$name/$id`}
          />

          <AuthorizedRoute
            path={PATHS.GLOBAL_SEARCH}
            exact
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.GLOBAL_SEARCH_RESULTS}>
                <CSR_ROUTES.GlobalSearchResultsPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />

          <Route
            path={PATHS.AUTH}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.OAUTH}>
                <CSR_ROUTES.OAuthPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />

          {/* REDIRECTS */}
          <Redirect from={PATHS.CASHBACK_PICKS} to={PATHS.KARMA_CASH} />
          <Redirect from={PATHS.CASHBACK_PICK} to={PATHS.KARMA_CASH_SINGLE} />

          <Route path={[PATHS.REDIRECT, PATHS.REF_INDEX, PATHS.REF_CAPTURE]} component={CSR_ROUTES.RedirectPage} />

          <Route path={PATHS.DEMO.SIGN_UP} component={CSR_ROUTES.OnboardingRedirect} />

          <Route
            path={PATHS.DEMO.SAFARI_STEP_1}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.ONBOARDING_SAFARI_STEP_1}>
                <CSR_ROUTES.OnboardingSafariStep1Page {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />

          <Route
            path={PATHS.DEMO.SAFARI_STEP_2}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.ONBOARDING_SAFARI_STEP_2}>
                <CSR_ROUTES.OnboardingSafariStep2Page {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />

          <Route
            path={PATHS.DEMO.SAFARI_SIGN_UP}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.ONBOARDING_SAFARI_SIGN_UP}>
                <CSR_ROUTES.OnboardingSafariSignUpPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />

          <Route path={PATHS.ONBOARDING.IOS_EXTENSION} component={CSR_ROUTES.OnboardingIOSExt} />
          {/* to keep backward compatibility for old mobile devices. do not change to <Redirect /> to not lose query params */}
          <Route path={PATHS.ONBOARDING.IOS_EXTENSION_V2} component={CSR_ROUTES.OnboardingIOSExt} />

          <Route
            path={PATHS.UNSUBSCRIBE}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.UNSUBSCRIBE}>
                <CSR_ROUTES.UnsubscribePage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />
          <Route
            path={PATHS.UNSUBSCRIBED}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.UNSUBSCRIBED}>
                <CSR_ROUTES.UnsubscribeResult {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />
          <Route
            path={PATHS.DISABLING_TAGS}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.UNSUBSCRIBE_TAG}>
                <CSR_ROUTES.UnsubscribeTagPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />
          <Route
            path={PATHS.MUTE_TAG}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.MUTE_TAG}>
                <CSR_ROUTES.MuteTagPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />
          <UnauthorizedRoute
            exact
            path={PATHS.WEBSITE_LANDING_BRB}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.LANDING_MAIN_OLD}>
                <SSR_ROUTES.WebsiteLandingKarmaOld {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            relatedAuthorizedLink={PATHS.DASHBOARD}
          />
          <UnauthorizedRoute
            exact
            path={PATHS.WEBSITE_LANDING_JOIN}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.LANDING_MAIN_JOIN}>
                <SSR_ROUTES.WebsiteLandingKarmaOld {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
            relatedAuthorizedLink={PATHS.DASHBOARD}
          />
          <UnauthorizedRoute
            exact
            path={PATHS.WEBSITE_LANDING_MEN}
            render={routeProps => <CSR_ROUTES.WebsiteLandingKarmaForMen {...routeProps} />}
            relatedAuthorizedLink={PATHS.DASHBOARD}
          />
          <UnauthorizedRoute
            exact
            path={PATHS.WEBSITE_LANDING_MARKETING_5}
            render={routeProps => (
              <CSR_ROUTES.WebsiteLandingKarma {...routeProps} redirectPath={PATHS.WEBSITE_LANDING_MARKETING_5} />
            )}
            relatedAuthorizedLink={PATHS.DASHBOARD}
          />
          <Route
            path={PATHS.PARTNERSHIPS}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.PARTNERSHIPS}>
                <CSR_ROUTES.PartnershipsPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />
          <Route
            path={PATHS.EXTENSION_DELETED}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.EXTENSION_DELETED}>
                <CSR_ROUTES.ExtensionDeletedPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />
          <Route
            path={PATHS.MOBILE_LOGIN}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.MOBILE_LOGIN}>
                <CSR_ROUTES.MobileLoginRedirectPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />

          {/* NOT FOUND */}
          <Route
            path={[PATHS.NGINX_NOT_FOUND, PATHS.NOT_FOUND, '*']}
            render={routeProps => (
              <Alooma.SourceContext.Provider value={ALOOMA.SOURCE_PAGES.NOT_FOUND}>
                <CSR_ROUTES.ErrorPage {...routeProps} />
              </Alooma.SourceContext.Provider>
            )}
          />
        </Switch>
      </Suspense>

      {userId && !ignoreKustomerButton && (
        <Alooma.SectionContext.Provider value={ALOOMA.SECTIONS.PAGE_MENU}>
          <KustomerButton />
        </Alooma.SectionContext.Provider>
      )}
    </AppLayout>
  );
};

export default compose(RetailersDBContextProvider())(App);
