import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodySuggestInstallRemovedExtension from 'components/molecules/ModalBodySuggestInstallRemovedExtension/ModalBodySuggestInstallRemovedExtension';
import Modal from 'components/templates/Modal/Modal';

const ModalSuggestInstallRemovedExtension = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.SUGGEST_INSTALL_REMOVED_EXTENSION} onClose={closeModal}>
      <ModalBodySuggestInstallRemovedExtension {...props} />
    </Modal>
  );
};

ModalSuggestInstallRemovedExtension.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalSuggestInstallRemovedExtension;
