import { Stack } from '@karma/components';
import { useTranslation } from 'react-i18next';
import { BackCircleArrowIcon, ClosedBoxIcon, DeliveryIcon } from '@karma/components/icons';
import StoreInfoBadge from 'components/atoms/StoreInfoBadge/StoreInfoBadge';
import StoreInfoBadgeDivider from 'components/atoms/StoreInfoBadgeDivider/StoreInfoBadgeDivider';

const StoreInfoShippingInfo = ({ shippingInfo }) => {
  const { t } = useTranslation();

  const shippingInfoMap = [
    {
      id: 1,
      enabledCondition: shippingInfo?.freeDeliveryExists,
      Icon: ClosedBoxIcon,
      enabledText: shippingInfo?.minOrderAmount ? (
        <>
          {t('app:freeShipping')}
          <br />
          {t('app:overMinAmount', {
            value: shippingInfo?.minOrderAmount,
            currency: '$',
          })}
        </>
      ) : (
        <>
          {t('app:freeShipping')}
          <br />
          {t('app:minOrderMayApply')}
        </>
      ),
      disabledText: t('app:shippingInfo'),
    },
    {
      id: 2,
      enabledCondition: shippingInfo?.deliveryDays,
      Icon: DeliveryIcon,
      enabledText: (
        <>
          {t('app:delivers')}
          <br />
          {t('app:deliveryDays', {
            value: shippingInfo?.deliveryDays,
          })}
        </>
      ),
      disabledText: t('app:deliveryInfo'),
    },
    {
      id: 3,
      enabledCondition: shippingInfo?.freeReturns,
      Icon: BackCircleArrowIcon,
      enabledText: (
        <>
          {t('app:freeReturns')}
          <br />
          {t('app:upToDays', {
            value: shippingInfo?.freeReturnsDays,
          })}
        </>
      ),
      disabledText: t('app:returnsInfo'),
    },
  ];

  return (
    <Stack justifyContent="flex-start">
      {shippingInfoMap.map(({ id, enabledCondition, Icon, enabledText, disabledText }) => {
        const text = enabledCondition ? (
          enabledText
        ) : (
          <>
            {disabledText} <br /> {t('app:notAvailable')}
          </>
        );

        return (
          <Stack key={id} justifyContent="flex-start">
            <StoreInfoBadge Icon={Icon} text={text} color={enabledCondition ? 'currentColor' : 'neutral3'} />
            {id !== shippingInfoMap.length && <StoreInfoBadgeDivider />}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default StoreInfoShippingInfo;
