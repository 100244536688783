import * as ALOOMA from 'consts/alooma';
import ModalBodyResetPassword from 'components/molecules/ModalBodyResetPassword/ModalBodyResetPassword';
import Modal from 'components/templates/Modal/Modal';

const ModalResetPassword = props => {
  return (
    <Modal showClose={false} source={ALOOMA.SOURCE_PAGES.MODAL_RESET_PASSWORD} title="Create new password">
      <ModalBodyResetPassword {...props} />
    </Modal>
  );
};

export default ModalResetPassword;
