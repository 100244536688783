import partial from 'lodash/partial';
import { MODAL_TYPES } from 'consts/modals';
import modalsSlice from './slice';

export const { bulkPushBack, popBack, popFront, pushBack, pushFront, reset } = modalsSlice.actions;

const _pushBack = (type, data) => pushBack({ type, data });

export const pushBackPartnershipsContactModal = partial(_pushBack, MODAL_TYPES.partnershipContact);
export const pushBackCompleteSignUpModal = partial(_pushBack, MODAL_TYPES.completeSignUp);
export const pushBackCongratsGuestModal = partial(_pushBack, MODAL_TYPES.congratsGuest);
export const pushBackCongratsUserModal = partial(_pushBack, MODAL_TYPES.congratsUser);
export const pushBackListCreateModal = partial(_pushBack, MODAL_TYPES.listCreate);
export const pushBackListDeleteModal = partial(_pushBack, MODAL_TYPES.listDelete);
export const pushBackListEditModal = partial(_pushBack, MODAL_TYPES.listEdit);
export const pushBackListShareModal = partial(_pushBack, MODAL_TYPES.listShare);
export const pushBackLogInModal = partial(_pushBack, MODAL_TYPES.logIn);
export const pushBackPaypalConfirmModal = partial(_pushBack, MODAL_TYPES.paypalConfirm);
export const pushBackPaypalConnectModal = partial(_pushBack, MODAL_TYPES.paypalConnect);
export const pushBackPaypalDisconnectModal = partial(_pushBack, MODAL_TYPES.paypalDisconnect);
export const pushBackResetPasswordModal = partial(_pushBack, MODAL_TYPES.resetPassword);
export const pushBackResetPasswordNewModal = partial(_pushBack, MODAL_TYPES.resetPasswordNew);
export const pushBackSignUpModal = partial(_pushBack, MODAL_TYPES.signUp);
export const pushBackAuthenticateModal = partial(_pushBack, MODAL_TYPES.authenticate);
export const pushBackSuggestInstallExtensionModal = partial(_pushBack, MODAL_TYPES.suggestInstallExtension);
export const pushBackSuggestInstallRemovedExtensionModal = partial(
  _pushBack,
  MODAL_TYPES.suggestInstallRemovedExtension,
);
export const pushBackTagCreateModal = partial(_pushBack, MODAL_TYPES.tagCreate);
export const pushBackTagsAddToListModal = partial(_pushBack, MODAL_TYPES.tagsAddToList);
export const pushBackTagsDeleteModal = partial(_pushBack, MODAL_TYPES.tagsDelete);
export const pushBackTagsFulfillModal = partial(_pushBack, MODAL_TYPES.tagsFulfill);
export const pushBackTagsMuteModal = partial(_pushBack, MODAL_TYPES.tagsMute);
export const pushBackWithdrawCongratsModal = partial(_pushBack, MODAL_TYPES.withdrawCongrats);
export const pushBackVideoHowToSaveModal = partial(_pushBack, MODAL_TYPES.videoHowToSave);
export const pushBackDoNotSellMyInfo = partial(_pushBack, MODAL_TYPES.doNotSellMyInfo);
export const pushBackChangeLanguageModal = partial(_pushBack, MODAL_TYPES.changeLanguage);
export const pushBackProductModal = partial(_pushBack, MODAL_TYPES.product);
export const pushBackCongratulateCompleteSignUpModal = partial(_pushBack, MODAL_TYPES.congratulateCompleteSignUp);
export const pushBackChatWithUsModal = partial(_pushBack, MODAL_TYPES.chatWithUs);
export const pushBackStoreInfoModal = partial(_pushBack, MODAL_TYPES.storeInfo);
export const pushBackManageStoresModal = partial(_pushBack, MODAL_TYPES.manageStores);
export const pushBackDeleteAccountModal = partial(_pushBack, MODAL_TYPES.deleteAccount);
export const pushBackOnboardingModal = partial(_pushBack, MODAL_TYPES.onboarding);

const _pushFront = (type, data) => pushFront({ type, data });

export const pushFrontCompleteSignUpModal = partial(_pushFront, MODAL_TYPES.completeSignUp);
export const pushFrontSignUpModal = partial(_pushFront, MODAL_TYPES.signUp);
export const pushFrontAuthenticateModal = partial(_pushFront, MODAL_TYPES.authenticate);
export const pushFrontUnderMaintenanceModal = partial(_pushFront, MODAL_TYPES.underMaintenance);
export const pushFrontItemSavedModal = partial(_pushFront, MODAL_TYPES.itemSaved);
