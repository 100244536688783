import PropTypes from 'prop-types';
import * as ALOOMA from 'consts/alooma';
import ModalBodySuggestInstallExtension from 'components/molecules/ModalBodySuggestInstallExtension/ModalBodySuggestInstallExtension';
import Modal from 'components/templates/Modal/Modal';

const ModalSuggestInstallExtension = props => {
  const { closeModal } = props;

  return (
    <Modal source={ALOOMA.SOURCE_PAGES.SUGGEST_INSTALL_EXTENSION} onClose={closeModal}>
      <ModalBodySuggestInstallExtension {...props} />
    </Modal>
  );
};

ModalSuggestInstallExtension.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ModalSuggestInstallExtension;
