import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@karma/components';
import { MailIcon } from '@karma/components/icons';
import { useSelector } from 'react-redux';
import { skipGuestSignUp } from 'functions/authentication/signUpFunctions';
import Alooma from 'services/alooma';
import * as ALOOMA from 'consts/alooma';
import * as userActions from 'store/user/actions';
import SocialLogin from 'components/molecules/SocialLogin/SocialLogin';
import TermsAndPrivacy from 'components/molecules/TermsAndPrivacy/TermsAndPrivacy';
import GoogleTarsAndConditions from 'components/molecules/GoogleTarsAndConditions/GoogleTarsAndConditions';
import SignUpWrapperNew from 'components/molecules/SignUpWrapperNew/SignUpWrapperNew';
import SignUpTabs from 'components/molecules/SignUpTabs/SignUpTabs';
import useIsGDPRCountry from 'hooks/useIsGDPRCountry';
import TermsAndPrivacyAgreement from 'components/molecules/TermsAndPrivacyAgreement/TermsAndPrivacyAgreement';
import { getExtInstalled } from 'store/extension/selectors';
import { AUTHENTICATE_TYPE } from 'consts/authenticateModal';
import { markUrl } from 'functions/markUrl';
import useAction from 'hooks/useAction';
import getIsSeoPage from 'functions/getIsSeoPage';
import useGetRedirectAfterAuthorization from 'hooks/useGetRedirectAfterAuthorization';
import EmailAuthorization from '../EmailAuthorization/EmailAuthorization';
import {
  ButtonsContainer,
  ContentContainer,
  TabsContainer,
  TermsAndConditionsText,
  Title,
} from './SignUpFormNew.style';

const SignUpFormNew = ({
  closeModal,
  authState,
  setAuthState,
  isCompleteLater,
  showImage = true,
  defaultEmailLogin,
}) => {
  const { t } = useTranslation();
  const isGDPRCountry = useIsGDPRCountry();
  const contextDataAttrs = Alooma.useContextDataAttrs();
  const fetchUser = useAction(userActions.fetchUser);
  const isExtensionInstalled = useSelector(getExtInstalled);
  const isSeoPage = getIsSeoPage();
  const redirectAfterAuthorization = useGetRedirectAfterAuthorization();

  const currentPath = window.location.pathname;

  const [agreementValue, setAgreementValue] = useState(false);
  const [regularLoginSelected, setRegularLoginSelected] = useState(defaultEmailLogin);
  const [termsError, setTermsError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);

  const isSignUpState = authState === AUTHENTICATE_TYPE.SIGN_UP;

  const openEmailAuthorization = () => {
    if (isSignUpState && isGDPRCountry && !agreementValue) {
      setTermsError(true);
    } else {
      setTermsError(false);
      setRegularLoginSelected(true);
    }
  };

  const onChangedTermsCheckbox = () => {
    if (!agreementValue && termsError) setTermsError(false);
    setAgreementValue(v => !v);
  };

  const socialLoginAgreement = useMemo(() => {
    if (!isSignUpState || !isGDPRCountry) return true;

    return agreementValue;
  }, [agreementValue, isGDPRCountry, isSignUpState]);

  const onSkipSignUp = useCallback(() => {
    setIsFetching(true);
    skipGuestSignUp()
      .then(() => {
        if (isSeoPage) {
          fetchUser();
          closeModal();
        } else {
          redirectAfterAuthorization(isExtensionInstalled);
          window.location.reload();
        }
      })
      .finally(() => setIsFetching(false));
  }, [closeModal, fetchUser, isExtensionInstalled, redirectAfterAuthorization, isSeoPage]);

  const secondaryButton = useMemo(() => {
    if (!isSignUpState) return null;

    if (isCompleteLater) {
      return (
        <Button
          type="underlinedText"
          size="small"
          onClick={closeModal}
          disabled={isFetching}
          {...contextDataAttrs}
          data-alooma-element={ALOOMA.ELEMENTS.CONTINUE_AS_GUEST}
        >
          {t('app:skipForNow')}
        </Button>
      );
    }

    return (
      <Button
        type="underlinedText"
        size="small"
        onClick={onSkipSignUp}
        disabled={isFetching}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.CONTINUE_AS_GUEST}
      >
        {t('app:illSignUpLater')}
      </Button>
    );
  }, [closeModal, contextDataAttrs, isCompleteLater, isFetching, isSignUpState, onSkipSignUp, t]);

  const changeTab = () => {
    if (isSignUpState) {
      markUrl(AUTHENTICATE_TYPE.SIGN_UP, 'false', false);
      markUrl(AUTHENTICATE_TYPE.LOGIN, 'true', true);
      setAuthState(AUTHENTICATE_TYPE.LOGIN);
    } else {
      markUrl(AUTHENTICATE_TYPE.LOGIN, 'false', false);
      markUrl(AUTHENTICATE_TYPE.SIGN_UP, 'true', true);
      setAuthState(AUTHENTICATE_TYPE.SIGN_UP);
    }
  };

  return (
    <SignUpWrapperNew showImage={showImage}>
      {regularLoginSelected ? (
        <EmailAuthorization
          isSignUpState={isSignUpState}
          goBack={() => setRegularLoginSelected(false)}
          isCompleteLater={isCompleteLater}
          closeModal={closeModal}
        />
      ) : (
        <ContentContainer fullWidth direction="column" justifyContent="space-between" $isGDPRCountry={isGDPRCountry}>
          <Stack fullWidth direction="column" spacing={32}>
            <TabsContainer fullWidth>
              <SignUpTabs isSignUpState={isSignUpState} changeTab={changeTab} />
            </TabsContainer>
            <Title variant="h3" align="center">
              {isSignUpState ? (
                <>
                  {t('app:signUpAndGet')}
                  <br />
                  {t('app:moneyOnUs', {
                    currency: '$',
                    value: 5,
                  })}
                </>
              ) : (
                t('app:heyYouLetsJumpBackIntoSaving')
              )}
            </Title>
            <ButtonsContainer direction="column" spacing={20}>
              <SocialLogin
                setFetching={setIsFetching}
                disabled={isFetching}
                agreementValue={socialLoginAgreement}
                setAgreementError={() => setTermsError(true)}
                fromSeo={isSeoPage}
                buttonSize="small"
                redirectTo={isSeoPage ? currentPath : null}
              />
              <Button
                Icon={MailIcon}
                fullWidth
                size="small"
                disabled={isFetching}
                onClick={openEmailAuthorization}
                {...contextDataAttrs}
                data-alooma-element={ALOOMA.ELEMENTS.EMAIL}
              >
                {t('app:continueWith', {
                  provider: t('app:email').toLowerCase(),
                })}
              </Button>
              {secondaryButton}
            </ButtonsContainer>
          </Stack>
          <Stack direction="column" spacing={5}>
            {isGDPRCountry && isSignUpState && (
              <TermsAndPrivacyAgreement
                error={termsError && t('app:termsAndConditionsError')}
                checked={agreementValue}
                onChangeHandler={onChangedTermsCheckbox}
              />
            )}
            <TermsAndConditionsText $smallWidth={isGDPRCountry || !isSignUpState}>
              {!isGDPRCountry && isSignUpState && <TermsAndPrivacy />} <GoogleTarsAndConditions />
            </TermsAndConditionsText>
          </Stack>
        </ContentContainer>
      )}
    </SignUpWrapperNew>
  );
};

SignUpFormNew.propTypes = {
  authState: PropTypes.oneOf(Object.values(AUTHENTICATE_TYPE)).isRequired,
  setAuthState: PropTypes.func.isRequired,
  isCompleteLater: PropTypes.bool,
  closeModal: PropTypes.func,
  showImage: PropTypes.bool,
  defaultEmailLogin: PropTypes.bool,
};

SignUpFormNew.defaultProps = {
  closeModal: () => null,
  showImage: true,
  isCompleteLater: false,
  defaultEmailLogin: false,
};

export default SignUpFormNew;
