import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@karma/components';
import * as ALOOMA from 'consts/alooma';
import useConnectExtensionHandler from 'hooks/useConnectExtensionHandler';
import Alooma from 'services/alooma';

const GetTheButton = ({ className, content }) => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const connectExtensionHandler = useConnectExtensionHandler();

  return (
    <Button
      variant="primary"
      width="100%"
      align="center"
      size="medium"
      {...contextDataAttrs}
      data-alooma-element={ALOOMA.ELEMENTS.GET_THE_BUTTON}
      className={className}
      onClick={connectExtensionHandler}
    >
      {t(content)}
    </Button>
  );
};

GetTheButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};

GetTheButton.defaultProps = {
  className: '',
  content: 'app:connectNow',
};

export default GetTheButton;
