export const COOKIES = {
  competitorsChecked: 'competitors_checked',
  featuresUpToDate: 'features_in_store_up_to_date',
  logged: 'logged',
  utmCampaign: 'utm_campaign',
  utmContent: 'utm_content',
  utmMedium: 'utm_medium',
  utmSource: 'utm_source',
  utmTerm: 'utm_term',
  lpTest: 'lp_test',
  doNotSellMyInfo: 'do_not_sell_my_info',
  gaClientId: '_ga',
};

export const UTM_TERMS = {
  COUPONS: 'coupons',
  PAY_K: 'payk',
  ALERTS: 'alerts',
  CASHBACK: 'cashback',
};
