import { Stack, Typography } from '@karma/components';

const StoreInfoBadge = ({ Icon, text, color }) => (
  <Stack spacing={12}>
    <Icon size="large" color={color} />
    <Typography variant="paragraphSmall" style={{ maxWidth: '130px' }} color={color}>
      {text}
    </Typography>
  </Stack>
);

export default StoreInfoBadge;
