import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack } from '@karma/components';
import { oldApiInstance } from 'functions/request';
import ROUTES from 'consts/routes';
import { PATHS } from 'consts/paths';
import { authenticateUser } from 'functions/authentication/authentication';
import { handleServerError, showSuccessAlert } from 'store/alerts/actions';
import useAction from 'hooks/useAction';
import { ITEMS } from 'consts/localStorage';
import { getUser } from 'store/user/api';
import { isMobile } from 'functions/isMobile';
import InputFormField from '../InputFormField/InputFormField';

const ModalBodyResetPassword = ({ resetPasswordToken }) => {
  const { t } = useTranslation();

  const schema = yup.object({
    password: yup
      .string()
      .test(
        'password',
        t('app:passwordValidationNote'),
        value => value?.length >= 8 && /[0-9]/g.test(value) && /[a-z]/g.test(value) && /[A-Z]/g.test(value),
      )
      .required(t('app:enterYourPassword')),
    'repeat-password': yup
      .string()
      .test(
        'repeat-password',
        t('app:passwordValidationNote'),
        value => value?.length >= 8 && /[0-9]/g.test(value) && /[a-z]/g.test(value) && /[A-Z]/g.test(value),
      )
      .required(t('app:enterYourPassword'))
      .oneOf([yup.ref('password')], 'Passwords must match'),
  });

  const [isFetching, setIsFetching] = useState(false);
  const handleServerErrorFunc = useAction(handleServerError);
  const showSuccessAlertFunc = useAction(showSuccessAlert);

  const userId = useRef(localStorage.getItem(ITEMS.userId));

  const { handleSubmit, control } = useForm({
    defaultValues: {
      password: '',
      'repeat-password': '',
    },
    resolver: yupResolver(schema),
  });

  const resetPassword = useCallback(
    ({ password, 'repeat-password': repeatPassword }) => {
      setIsFetching(true);

      oldApiInstance
        .put(ROUTES.api.resetPasswordPath, {
          reset_password_token: resetPasswordToken,
          password,
          password_confirmation: repeatPassword,
        })
        .then(({ data }) => {
          if (isMobile()) {
            showSuccessAlertFunc({ message: t('app:passwordWasSuccessfullyReset') });
          } else if (userId) {
            /**
             * Truthy userId means that we trying to reset the password for the account during the
             * user is logged as guest. We authenticate user to correctly send next requests. We
             * fetch user to receive missed data. If all data exist we use email login to merge
             * account with guest user.
             */
            authenticateUser(data);

            getUser().then(response => {
              oldApiInstance
                .post('/sessions/email_login', {
                  email: response.data.email,
                  password,
                  merge_guest_user_id: +userId.current,
                })
                .then(({ data: data2 }) => {
                  authenticateUser(data2);
                  window.location.href = PATHS.DASHBOARD;
                });
            });
          } else {
            authenticateUser(data);
            window.location.href = PATHS.DASHBOARD;
          }
        })
        .catch(error => {
          handleServerErrorFunc(error?.response);
          setIsFetching(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetPasswordToken, t],
  );

  const defaultProps = {
    control,
    disabled: isFetching,
    fullWidth: true,
  };

  const handleSubmitFunc = handleSubmit(resetPassword);

  return (
    <form onSubmit={handleSubmitFunc}>
      <Stack direction="column" spacing={16}>
        <InputFormField
          id="password"
          name="password"
          label={t('app:password')}
          placeholder={t('app:password')}
          type="password"
          {...defaultProps}
        />
        <InputFormField
          id="repeat-password"
          name="repeat-password"
          label="Repeat Password"
          placeholder="Password confirmation"
          type="password"
          {...defaultProps}
        />
        <Button size="large" fullWidth onClick={handleSubmitFunc} loading={isFetching}>
          {t('app:resetMyPassword')}
        </Button>
      </Stack>
    </form>
  );
};

ModalBodyResetPassword.propTypes = {
  resetPasswordToken: PropTypes.string.isRequired,
};

export default ModalBodyResetPassword;
