import { createSelector } from '@reduxjs/toolkit';
import { ROLES } from 'consts/common';
import { calculateBalance } from 'functions/balance';

const getRootEntity = ({ user }) => {
  return user;
};

export const getLoaded = createSelector([getRootEntity], ({ loaded }) => {
  return loaded;
});

export const getUser = createSelector([getRootEntity], ({ user }) => {
  return user;
});

export const getUserPhoneNumber = createSelector([getUser], ({ phone_number: phoneNumber }) => {
  return phoneNumber;
});

export const getGuest = createSelector([getUser], ({ guest }) => {
  return guest;
});

export const getUserRoles = createSelector([getUser], ({ roles }) => roles);

export const isAdmin = createSelector(getUserRoles, roles => roles.includes(ROLES.admin));

export const getUserId = createSelector([getUser], ({ id }) => {
  return id;
});

export const getUserName = createSelector([getUser], ({ name }) => {
  return name;
});

export const getUserFirstName = createSelector([getUser], ({ first_name }) => {
  return first_name;
});

export const getUserEmail = createSelector([getUser], ({ email }) => {
  return email;
});

export const getUserCountryCode = createSelector([getUser], ({ country_code }) => country_code);

export const getUserLocationIsCalifornia = createSelector([getUser], ({ state_code, country_code }) => {
  return country_code === 'US' && state_code === 'CA';
});

export const getWallet = createSelector([getUser], ({ wallet }) => {
  return wallet;
});

export const getBalance = createSelector([getUser], ({ wallet }) => {
  return wallet?.balance;
});

export const getPendingBalance = createSelector([getUser], ({ wallet }) => {
  return wallet?.pending_balance;
});

export const getFullBalance = createSelector([getBalance, getPendingBalance], (balance, pending_balance) =>
  calculateBalance({ balance, pending_balance }),
);

export const getAcceptedCookies = createSelector([getUser], ({ accepted_cookies }) => {
  return accepted_cookies;
});

export const getMinimalWithdrawAmount = createSelector(
  getWallet,
  ({ minimal_withdraw_amount } = {}) => minimal_withdraw_amount,
);

export const getUserIdAndEmail = createSelector([getUser], ({ id, email }) => {
  return { id, email };
});

export const getDonationCause = createSelector(
  getWallet,
  ({ donation_cause } = { donation_cause: '' }) => donation_cause,
);

export const getUserNotShownBonus = createSelector([getUser], ({ not_shown_bonus }) => {
  return not_shown_bonus;
});

export const getUserLanguage = createSelector(getUser, user => user?.language);

export const getIsRegisteredUser = createSelector([getRootEntity], ({ loaded, user }) => {
  return !!(loaded && user?.id && !user?.guest);
});

const getAccountDelete = createSelector([getRootEntity], ({ accountDelete }) => {
  return accountDelete;
});

export const getAccountDeleteIsFetching = createSelector([getAccountDelete], ({ isFetching }) => {
  return isFetching;
});

export const getAccountDeleteSuccess = createSelector([getAccountDelete], ({ success }) => {
  return success;
});
