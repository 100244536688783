import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WinkIcon, RightArrowIcon } from '@karma/components/icons';
import { getRedirectLinkToTag } from '@karma/utils/functions/redirectLink';
import { Stack, Typography, Button } from '@karma/components';
import { ReferralSourceContext } from 'contexts/ReferralSourceContext';
import * as ALOOMA from 'consts/alooma';
import Alooma from 'services/alooma';
import * as onboardingSelectors from 'store/onboarding/selectors';
import { PATHS } from 'consts/paths';

const ModalBodyCongratsUser = () => {
  const { t } = useTranslation();
  const contextDataAttrs = Alooma.useContextDataAttrs();

  const source = useContext(ReferralSourceContext);

  const lastSavedTag = useSelector(onboardingSelectors.getLastSavedTag);

  const getUrl = () => {
    const data = {
      tagId: lastSavedTag.id,
      ruleName: lastSavedTag.rule_name,
      source,
      userId: lastSavedTag.user_id,
      cashback: !!lastSavedTag.stake,
      originalUrl: lastSavedTag.original_url,
    };

    return getRedirectLinkToTag(data);
  };

  const takeBack = () => {
    Alooma.UNSAFE_trackAloomaEvent('Signup Tutorial Continue To The Previous Store');
    window.open(getUrl(), '_blank');
  };

  const otherStores = () => {
    Alooma.UNSAFE_trackAloomaEvent('Signup Tutorial Continue To Supported Stores');
    window.location.href = PATHS.STORES;
  };

  return (
    <Stack direction="column" align="center" spacing={20}>
      <WinkIcon width={54} height={54} />

      <div>
        <Typography variant="h3" align="center">
          {t('app:congrats')}
        </Typography>
        <Typography variant="h4" align="center" bMargin={8}>
          {t('app:savedFirstItem')}
        </Typography>
        <Typography variant="paragraphLarge" align="center">
          {t('app:continueAndNotify')}
        </Typography>
      </div>

      <Button
        size="medium"
        fullWidth
        type="primary"
        iconPosition="right"
        icon={RightArrowIcon}
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.TAKE_ME_BACK_TO_STORE}
        onClick={takeBack}
      >
        {t('app:takeMeBack')} {lastSavedTag.rule_name}
      </Button>

      <Button
        size="medium"
        fullWidth
        type="outline"
        {...contextDataAttrs}
        data-alooma-element={ALOOMA.ELEMENTS.I_WANT_TO_SEE_OTHER_SUPPORTED_STORES}
        onClick={otherStores}
      >
        {t('app:otherSupportedStores')}
      </Button>
    </Stack>
  );
};

export default ModalBodyCongratsUser;
