import { call, put } from 'redux-saga/effects';
import { handleServerError, showSuccessAlert } from 'store/alerts/actions';
import * as userActions from '../actions';
import * as userApi from '../api';

function* updateUser(action) {
  try {
    const response = yield call(userApi.putUser, action.payload);

    yield put(userActions.saveUser(response.data));

    if (action.payload && !action.payload.accepted_cookies) {
      yield put(showSuccessAlert({ message: 'app:changesSaved' }));
    }

    if (action.payload.new_password) {
      yield put(userActions.clearPassword());
    }

    if (action.payload) yield put(userActions.updateUserSuccess());
  } catch (error) {
    yield put(userActions.updateUserError(error));
    yield put(handleServerError(error?.response));
  }
}

export default updateUser;
