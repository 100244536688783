import { ITEMS } from 'consts/localStorage';
import { PATHS } from 'consts/paths';
import { checkConnectPayPalError } from 'functions/checkConnectPayPalError';
import { removeCookie } from 'functions/cookies';
import { clearRecentGlobalSearches } from 'functions/recentGlobalSearchesFunctions';
import { removeBrazeToken } from 'services/braze';

export default function logout() {
  checkConnectPayPalError();
  localStorage.removeItem('token');
  localStorage.removeItem('language');
  localStorage.removeItem('userToken');
  localStorage.removeItem('user_id');
  localStorage.removeItem('influencer');
  localStorage.removeItem('influencerImage');
  localStorage.removeItem('influencerName');
  localStorage.removeItem('preferred_currency');
  localStorage.removeItem('number_of_dashboard_loads');
  localStorage.removeItem(ITEMS.features);
  removeBrazeToken();
  clearRecentGlobalSearches();

  removeCookie('extension_token', { path: '/' });
  removeCookie('logged', { path: '/' });
  removeCookie('features_up_to_date');
  window.postMessage('extension_logout', '*');
  window.location.href = PATHS.WEBSITE_LANDING_PATH;
}
